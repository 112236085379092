/* eslint-disable prefer-const */
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  selectAllDataDetectedLocation,
  selectDetectedLocationDetail,
  selectDetectedLocationList,
  selectDetectedLocationSearch,
  selectRoute,
  selectLatLng,
  setSearchValue
} from '../detectedLocationSlice';
import {
  TileLayer,
  Marker,
  MapContainer,
  Circle,
  Polyline,
  useMap,
  ScaleControl,
  CircleMarker,
  useMapEvents,
  LayersControl,
  Popup,
  Tooltip
} from 'react-leaflet';
import L, { LatLng, LatLngExpression } from 'leaflet';
import LocationIconBlue from '../../../Assets/images/location-pin-blue.png';
import MakerBlue from '../../../Assets/images/maker_blue.png';
import MakerRed from '../../../Assets/images/maker_red.png';
import LocationPrimaryIcon from '../../../Assets/images/location-pin-primary-color.png';
import React, { useRef, useEffect, useState } from 'react';
import { selectSelectedLocation, selectSelectedLocationB } from '../../onemap/onemapSlice';
import LocationRedIcon from '../../../Assets/images/location-pin-red.png';
import 'leaflet.polylinemeasure';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import { setDetectedLocationDetail } from '../detectedLocationSlice';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'polyline-encoded';
import io from 'socket.io-client';

const LocationDetail = new L.Icon({
  iconUrl: LocationPrimaryIcon,
  iconSize: [42, 42],
  iconAnchor: [16, 32]
});
const DefaultIcon = L.icon({
  iconUrl: LocationIconBlue,
  iconSize: [26, 26],
  iconAnchor: [16, 32]
});
const SelectedLocation = new L.Icon({
  iconUrl: LocationRedIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});
const BlueIcon = L.icon({
  iconUrl: LocationIconBlue,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});
const MakerIconRed = L.icon({
  iconUrl: MakerRed,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

const MakerIconBlue = L.icon({
  iconUrl: MakerBlue,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

L.Marker.prototype.options.icon = DefaultIcon;

const DraggableMarker: any = ({ initialPosition, onPositionChange, tooltip, location, icon }: any) => {
  const [position, setPosition] = useState(initialPosition);
  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  const eventHandlers = {
    dragend(event: any) {
      const marker = event.target;
      const newPosition = marker.getLatLng();
      setPosition(newPosition);
      onPositionChange([newPosition.lat, newPosition.lng]);
    }
  };

  return (
    <Marker icon={icon} position={position} draggable={true} eventHandlers={eventHandlers}>
      <Tooltip>
        <div>
          <div>Location {location}</div>
          <div>{tooltip}</div>
        </div>
      </Tooltip>
    </Marker>
  );
};

// SW3 E Ken Eng Seah Khiang
const coordinatesSW1 = [
  [1.2806959, 103.8175558],
  [1.2772849, 103.8159679],
  [1.2753489, 103.8146912],
  [1.2741422, 103.813629],
  [1.2727156, 103.811773],
  [1.2711174, 103.8111829],
  [1.2707956, 103.8111078],
  [1.2673096, 103.8171052],
  [1.2662584, 103.8186126],
  [1.2655076, 103.82012],
  [1.2657757, 103.8249158],
  [1.2660654, 103.8257097],
  [1.2692671, 103.8290732],
  [1.2709967, 103.8310554],
  [1.2720398, 103.8324367],
  [1.272249, 103.8398128],
  [1.2725037, 103.8446381],
  [1.2721149, 103.847103],
  [1.2721739, 103.8482332],
  [1.2731821, 103.8502503],
  [1.2733537, 103.8515592],
  [1.273225, 103.852439],
  [1.2701573, 103.8569021],
  [1.267154, 103.8586402],
  [1.2647513, 103.8608933],
  [1.2653734, 103.8618374],
  [1.2678619, 103.860035],
  [1.2701788, 103.8633395],
  [1.2704362, 103.8631463],
  [1.276636, 103.8715363],
  [1.2775584, 103.8721371],
  [1.2788026, 103.8722015],
  [1.2802185, 103.8714934],
  [1.2808406, 103.8714075],
  [1.2817631, 103.8706994],
  [1.2816343, 103.8704419],
  [1.2834792, 103.8681245],
  [1.2841872, 103.8672018],
  [1.2857103, 103.863082],
  [1.2860964, 103.8619876],
  [1.2868473, 103.8594342],
  [1.2864611, 103.8587261],
  [1.2854958, 103.858769],
  [1.2848093, 103.8586402],
  [1.2803901, 103.8558507],
  [1.2810122, 103.8547135],
  [1.2814627, 103.854971],
  [1.2816129, 103.8547778],
  [1.2822994, 103.8546062],
  [1.2821707, 103.8539195],
  [1.2822565, 103.853705],
  [1.2818918, 103.8535118],
  [1.2821263, 103.8530068],
  [1.2825473, 103.8523147],
  [1.2812863, 103.8514678],
  [1.2799502, 103.8506101],
  [1.2799918, 103.8505297],
  [1.2814627, 103.8514505],
  [1.2818261, 103.8508919],
  [1.2825849, 103.85136],
  [1.2825876, 103.851254],
  [1.2825327, 103.851199],
  [1.2814751, 103.8505105],
  [1.2812757, 103.850321],
  [1.2813327, 103.8502208],
  [1.2814949, 103.8503154],
  [1.2816746, 103.8503384],
  [1.2822431, 103.850326],
  [1.2823901, 103.8502689],
  [1.2824674, 103.8501073],
  [1.2824758, 103.8500369],
  [1.2817256, 103.8495233],
  [1.2848844, 103.8441563],
  [1.2864397, 103.8454974],
  [1.2895931, 103.8480509],
  [1.2898995, 103.8482936],
  [1.2900082, 103.8479931],
  [1.2896467, 103.8477049],
  [1.2896789, 103.8475466],
  [1.2897219, 103.8469994],
  [1.2896776, 103.8467473],
  [1.2895476, 103.8464737],
  [1.2892714, 103.8459265],
  [1.2889282, 103.8450709],
  [1.2891132, 103.8446807],
  [1.2894215, 103.8443816],
  [1.2900222, 103.8440812],
  [1.2907301, 103.8440812],
  [1.2909446, 103.8437808],
  [1.2906872, 103.8432658],
  [1.290537, 103.8425362],
  [1.2904298, 103.8413775],
  [1.2904298, 103.8405621],
  [1.289872, 103.840133],
  [1.2892928, 103.8394892],
  [1.2890944, 103.838706],
  [1.2891105, 103.8382662],
  [1.2892714, 103.838073],
  [1.288992, 103.8379358],
  [1.2890859, 103.8368038],
  [1.2893621, 103.8359402],
  [1.2896115, 103.8348244],
  [1.2901605, 103.833561],
  [1.2902041, 103.8332901],
  [1.2909017, 103.8329232],
  [1.2920704, 103.8320248],
  [1.2926496, 103.8316245],
  [1.2955457, 103.8304309],
  [1.2951167, 103.8292669],
  [1.292891, 103.8261501],
  [1.2928642, 103.8258122],
  [1.2907619, 103.8260053],
  [1.2909591, 103.8233565],
  [1.2927923, 103.8094101],
  [1.292505, 103.8081391],
  [1.2937846, 103.806947],
  [1.2973597, 103.8045103],
  [1.3005226, 103.8011788],
  [1.2995846, 103.8010387],
  [1.3032085, 103.7971374],
  [1.3013008, 103.7949018],
  [1.2974394, 103.796436],
  [1.295911, 103.79782],
  [1.2943275, 103.7987266],
  [1.2937282, 103.7986998],
  [1.2923606, 103.798901],
  [1.2868527, 103.798952],
  [1.2856406, 103.799306],
  [1.2846967, 103.8000946],
  [1.2843106, 103.8013981],
  [1.2835276, 103.8121055],
  [1.2827124, 103.8142513],
  [1.2806959, 103.8175558]
];

// SW2 E Caleigh Koh Hui Bing
const coordinatesSW2 = [
  [1.2102049, 103.642604],
  [1.2108848, 103.6443503],
  [1.2122577, 103.6450369],
  [1.2204956, 103.6397154],
  [1.2199808, 103.6270125],
  [1.2168916, 103.6268408],
  [1.2192943, 103.6158545],
  [1.230793, 103.6165412],
  [1.230793, 103.6210043],
  [1.2337105, 103.6210043],
  [1.2340538, 103.6191161],
  [1.2385159, 103.6167128],
  [1.2419484, 103.6165412],
  [1.2446943, 103.6156828],
  [1.2455524, 103.6369689],
  [1.2481267, 103.644522],
  [1.2539618, 103.6443503],
  [1.2544767, 103.6172278],
  [1.2567077, 103.6173995],
  [1.2604834, 103.6168845],
  [1.260655, 103.6392005],
  [1.2621996, 103.6433203],
  [1.2702657, 103.643492],
  [1.2704373, 103.6404021],
  [1.2790182, 103.6414321],
  [1.2785034, 103.6452086],
  [1.2810777, 103.6495001],
  [1.2855397, 103.6507018],
  [1.3085365, 103.6381705],
  [1.3124836, 103.6354239],
  [1.3167741, 103.6374838],
  [1.3116256, 103.642977],
  [1.3095662, 103.6464102],
  [1.3051041, 103.6495001],
  [1.3009853, 103.6500151],
  [1.2960084, 103.6584265],
  [1.288114, 103.6668379],
  [1.2966949, 103.6790259],
  [1.2990975, 103.6843474],
  [1.2999878, 103.6915035],
  [1.3008351, 103.6936815],
  [1.2995695, 103.6955805],
  [1.2994837, 103.6993892],
  [1.3019077, 103.7023933],
  [1.3021866, 103.7112982],
  [1.2995694, 103.7113627],
  [1.2995051, 103.7219628],
  [1.3001272, 103.7282927],
  [1.3008137, 103.7300093],
  [1.2956652, 103.7487204],
  [1.297553, 103.7562735],
  [1.293949, 103.7629683],
  [1.2906453, 103.7605651],
  [1.283845, 103.7723668],
  [1.278675, 103.769191],
  [1.2860546, 103.7564452],
  [1.2855397, 103.7530119],
  [1.2805198, 103.7500937],
  [1.2680774, 103.7706931],
  [1.2783905, 103.7769048],
  [1.2726413, 103.7866037],
  [1.2593408, 103.7782781],
  [1.2548787, 103.7854021],
  [1.2703674, 103.7952297],
  [1.2659482, 103.8010233],
  [1.26243, 103.8052933],
  [1.263524, 103.8056796],
  [1.2647468, 103.8065379],
  [1.2650257, 103.8063233],
  [1.2659053, 103.8072889],
  [1.2652617, 103.8078468],
  [1.264897, 103.8087695],
  [1.2650472, 103.8102286],
  [1.2665274, 103.8111942],
  [1.26627, 103.8129752],
  [1.2638887, 103.8156359],
  [1.2635884, 103.8175457],
  [1.2641033, 103.8176315],
  [1.2638673, 103.8195627],
  [1.2629877, 103.8196914],
  [1.2631593, 103.8237898],
  [1.2656907, 103.823704],
  [1.2655291, 103.8200985],
  [1.2708171, 103.8110863],
  [1.2727371, 103.8117515],
  [1.2741637, 103.8136075],
  [1.2753704, 103.8146697],
  [1.2773064, 103.8159464],
  [1.2807174, 103.8175343],
  [1.2827124, 103.8142513],
  [1.2835276, 103.8121055],
  [1.2843106, 103.8013981],
  [1.2846967, 103.8000946],
  [1.2856406, 103.799306],
  [1.2868527, 103.798952],
  [1.2919584, 103.7989466],
  [1.2928433, 103.7988661],
  [1.2937282, 103.7986998],
  [1.2943275, 103.7987266],
  [1.2949268, 103.798496],
  [1.295911, 103.79782],
  [1.2974394, 103.796436],
  [1.2983163, 103.7960122],
  [1.2993755, 103.795685],
  [1.3003757, 103.7953417],
  [1.3013008, 103.7949018],
  [1.3023163, 103.796084],
  [1.3026632, 103.7967153],
  [1.3032085, 103.7971374],
  [1.3060601, 103.7939871],
  [1.3072219, 103.7922428],
  [1.3076973, 103.7908633],
  [1.3079797, 103.7903743],
  [1.3080904, 103.7897565],
  [1.3081258, 103.7891092],
  [1.3081327, 103.7886569],
  [1.3083113, 103.7883117],
  [1.3085397, 103.787686],
  [1.3112124, 103.7844317],
  [1.3123317, 103.7816999],
  [1.312986, 103.778846],
  [1.3142946, 103.7773868],
  [1.3187694, 103.7709713],
  [1.3213778, 103.768311],
  [1.3195317, 103.7666051],
  [1.3188841, 103.7662695],
  [1.3181721, 103.766127],
  [1.3170914, 103.766078],
  [1.3166676, 103.7658085],
  [1.3161203, 103.7651406],
  [1.3158184, 103.7645801],
  [1.3166918, 103.7642515],
  [1.3175224, 103.7638157],
  [1.3185375, 103.7630649],
  [1.3194619, 103.7625072],
  [1.3210104, 103.7614994],
  [1.3233634, 103.7598586],
  [1.3233795, 103.7589815],
  [1.3245272, 103.7570128],
  [1.3246774, 103.7559077],
  [1.3225751, 103.7523242],
  [1.3220173, 103.7521096],
  [1.323519, 103.7471744],
  [1.3242913, 103.7434408],
  [1.3273374, 103.7387201],
  [1.3277236, 103.7364026],
  [1.3255569, 103.7270524],
  [1.3239909, 103.7192043],
  [1.3248289, 103.7190544],
  [1.3257527, 103.7189475],
  [1.3265906, 103.7191839],
  [1.3273428, 103.7195919],
  [1.328654, 103.7204509],
  [1.3300511, 103.7209236],
  [1.3318638, 103.7208834],
  [1.3338159, 103.7199017],
  [1.3381277, 103.7167796],
  [1.3404767, 103.7157979],
  [1.3430402, 103.7156745],
  [1.3408521, 103.7090656],
  [1.3471752, 103.7048706],
  [1.3458185, 103.7016413],
  [1.3447837, 103.6999997],
  [1.3435343, 103.6984869],
  [1.3418721, 103.6963948],
  [1.3403117, 103.6951556],
  [1.3399954, 103.6945601],
  [1.3391913, 103.6940478],
  [1.3389664, 103.6930849],
  [1.3383284, 103.6929735],
  [1.3377118, 103.6931626],
  [1.3367576, 103.6938842],
  [1.3360814, 103.694096],
  [1.3350834, 103.6941148],
  [1.3351469, 103.6916847],
  [1.334573, 103.6911161],
  [1.3321707, 103.6891956],
  [1.3305217, 103.6878867],
  [1.3299882, 103.6870498],
  [1.3302727, 103.6813636],
  [1.3307288, 103.6758489],
  [1.3308371, 103.6750979],
  [1.3304306, 103.6743469],
  [1.3296408, 103.6714394],
  [1.3286581, 103.6696852],
  [1.3277611, 103.6679311],
  [1.326297, 103.666161],
  [1.3229398, 103.6641547],
  [1.3355321, 103.6510012],
  [1.3477596, 103.6376545],
  [1.3453999, 103.6356374],
  [1.3274662, 103.6292001],
  [1.3281526, 103.6264535],
  [1.3192286, 103.6204453],
  [1.3147666, 103.6204453],
  [1.312364, 103.6237069],
  [1.2945158, 103.617098],
  [1.2892815, 103.6189004],
  [1.2847336, 103.6188146],
  [1.2799283, 103.6168405],
  [1.2773087, 103.6113617],
  [1.2652954, 103.6068985],
  [1.2599752, 103.6055252],
  [1.2462455, 103.6056968],
  [1.2194725, 103.6051818],
  [1.2102049, 103.642604]
];

// SW1 AE Nga Bing Chong
const coordinatesSW3 = [
  [1.3477596, 103.6376545],
  [1.3229398, 103.6641547],
  [1.326297, 103.666161],
  [1.3277611, 103.6679311],
  [1.3286581, 103.6696852],
  [1.3296408, 103.6714394],
  [1.3304306, 103.6743469],
  [1.3308371, 103.6750979],
  [1.3307288, 103.6758489],
  [1.3302727, 103.6813636],
  [1.3299882, 103.6870498],
  [1.3305217, 103.6878867],
  [1.3351469, 103.6916847],
  [1.3350834, 103.6941148],
  [1.3360814, 103.694096],
  [1.3370501, 103.6937063],
  [1.3383284, 103.6929735],
  [1.3389664, 103.6930849],
  [1.3391913, 103.6940478],
  [1.3399954, 103.6945601],
  [1.3403117, 103.6951556],
  [1.3418721, 103.6963948],
  [1.3458185, 103.7016413],
  [1.3471752, 103.7048706],
  [1.3408521, 103.7090656],
  [1.3430402, 103.7156745],
  [1.3404767, 103.7157979],
  [1.3381277, 103.7167796],
  [1.3338159, 103.7199017],
  [1.3318638, 103.7208834],
  [1.3300511, 103.7209236],
  [1.328654, 103.7204509],
  [1.3265906, 103.7191839],
  [1.3257527, 103.7189475],
  [1.3239909, 103.7192043],
  [1.3277236, 103.7364026],
  [1.3273374, 103.7387201],
  [1.3242913, 103.7434408],
  [1.323519, 103.7471744],
  [1.3227203, 103.7497323],
  [1.3220173, 103.7521096],
  [1.3225751, 103.7523242],
  [1.3246774, 103.7559077],
  [1.3245272, 103.7570128],
  [1.3233795, 103.7589815],
  [1.3234492, 103.7608671],
  [1.3242564, 103.7620674],
  [1.3236829, 103.7639632],
  [1.3255948, 103.7639587],
  [1.3282978, 103.7638085],
  [1.3301614, 103.7638774],
  [1.3324353, 103.7641349],
  [1.3351812, 103.7598004],
  [1.3376756, 103.7605963],
  [1.3379545, 103.7617336],
  [1.3382548, 103.7621627],
  [1.3392201, 103.7626348],
  [1.3393488, 103.7637721],
  [1.3396492, 103.7646518],
  [1.339796, 103.7665551],
  [1.3395633, 103.7682782],
  [1.3403785, 103.7697802],
  [1.3405286, 103.7705366],
  [1.3412151, 103.7718616],
  [1.3405555, 103.7725858],
  [1.3404395, 103.7735074],
  [1.3404214, 103.775445],
  [1.3406198, 103.7761156],
  [1.3397913, 103.7765903],
  [1.3384586, 103.7775908],
  [1.3380724, 103.7780548],
  [1.337689, 103.7791022],
  [1.3387736, 103.7794489],
  [1.3393079, 103.779531],
  [1.3397011, 103.7795212],
  [1.340088, 103.779366],
  [1.3409845, 103.7787817],
  [1.3497208, 103.7721835],
  [1.3487099, 103.7702496],
  [1.3497275, 103.7700122],
  [1.3507082, 103.7699364],
  [1.3520322, 103.7697534],
  [1.3533487, 103.7694181],
  [1.3544723, 103.7689541],
  [1.355219, 103.7687234],
  [1.3561804, 103.7682782],
  [1.3572234, 103.7674735],
  [1.3581727, 103.7673019],
  [1.3599639, 103.7664006],
  [1.362152, 103.7665079],
  [1.3632783, 103.7668244],
  [1.3640826, 103.7668083],
  [1.3654954, 103.7663739],
  [1.36624, 103.7660477],
  [1.3699561, 103.7635058],
  [1.3710981, 103.762981],
  [1.3748754, 103.7621214],
  [1.3782345, 103.7606288],
  [1.3814415, 103.7589872],
  [1.3839192, 103.7576032],
  [1.3868419, 103.7558168],
  [1.3898933, 103.7540304],
  [1.3933578, 103.7532412],
  [1.3956794, 103.7528612],
  [1.3983345, 103.7524166],
  [1.4008228, 103.752786],
  [1.406293, 103.754538],
  [1.4093391, 103.7554147],
  [1.4117202, 103.7554117],
  [1.4135009, 103.7549797],
  [1.4182094, 103.7541106],
  [1.4193677, 103.7544969],
  [1.4204457, 103.7548671],
  [1.4233872, 103.7565542],
  [1.4237632, 103.757676],
  [1.4243696, 103.759621],
  [1.4248122, 103.7604754],
  [1.4268006, 103.763198],
  [1.4298359, 103.7620178],
  [1.4330428, 103.7620607],
  [1.4352523, 103.7629834],
  [1.4399071, 103.7680903],
  [1.4449695, 103.7668887],
  [1.4378523, 103.7611224],
  [1.4380239, 103.7537409],
  [1.4373374, 103.7491061],
  [1.4404264, 103.7432696],
  [1.4393967, 103.741553],
  [1.4395683, 103.7393214],
  [1.4438585, 103.7369181],
  [1.4496931, 103.73091],
  [1.4514092, 103.7233569],
  [1.450208, 103.7152888],
  [1.4450598, 103.7061907],
  [1.4356214, 103.6970927],
  [1.4339053, 103.6902262],
  [1.427899, 103.675635],
  [1.4143419, 103.6711718],
  [1.4066195, 103.6636187],
  [1.3853398, 103.6584689],
  [1.3722973, 103.6495425],
  [1.3580535, 103.6406161],
  [1.3477596, 103.6376545]
];

// NE4 EE Tang Kah Leong
const coordinatesNE4 = [
  [1.3294352, 103.8479817],
  [1.3293441, 103.8498432],
  [1.3290786, 103.8528339],
  [1.3289191, 103.8547691],
  [1.3286737, 103.8560819],
  [1.328781, 103.8570583],
  [1.329033, 103.8578576],
  [1.3293709, 103.8586784],
  [1.329993, 103.8604325],
  [1.3300573, 103.8620579],
  [1.3314517, 103.8620901],
  [1.3320631, 103.8605559],
  [1.3330928, 103.8588644],
  [1.3330392, 103.8581493],
  [1.3339569, 103.8581579],
  [1.3351535, 103.8581315],
  [1.3353908, 103.8581111],
  [1.3353761, 103.8587397],
  [1.3353613, 103.8592182],
  [1.3353654, 103.8594111],
  [1.3354659, 103.8596041],
  [1.3356523, 103.8597219],
  [1.3359137, 103.8597486],
  [1.3375012, 103.8597473],
  [1.3387025, 103.8596876],
  [1.3390886, 103.8595313],
  [1.3393568, 103.8592886],
  [1.3395605, 103.8589601],
  [1.3397107, 103.8585993],
  [1.3397214, 103.8581742],
  [1.3397241, 103.853888],
  [1.3401344, 103.8531638],
  [1.341266, 103.8535071],
  [1.3430572, 103.8537646],
  [1.3436793, 103.8538129],
  [1.343572, 103.8572944],
  [1.3484201, 103.8569618],
  [1.3499941, 103.8562269],
  [1.3515681, 103.855492],
  [1.3547081, 103.8530833],
  [1.3570725, 103.8508048],
  [1.359823, 103.8524745],
  [1.3601756, 103.8520373],
  [1.3571778, 103.8499881],
  [1.3624066, 103.8437868],
  [1.3625943, 103.8426763],
  [1.3639552, 103.8432624],
  [1.3641147, 103.8422177],
  [1.3624294, 103.8420312],
  [1.3629322, 103.8399994],
  [1.3668846, 103.8306547],
  [1.3671205, 103.8284104],
  [1.3696512, 103.8286216],
  [1.3713854, 103.828739],
  [1.3732215, 103.8287114],
  [1.3765807, 103.8285231],
  [1.3773369, 103.8284694],
  [1.3785864, 103.8281315],
  [1.3811713, 103.8267957],
  [1.3821224, 103.8263086],
  [1.3833094, 103.8288095],
  [1.3878574, 103.8316806],
  [1.3892451, 103.8293827],
  [1.3896821, 103.8280483],
  [1.3844212, 103.824607],
  [1.3855444, 103.8228964],
  [1.387236, 103.8202792],
  [1.3881108, 103.8189368],
  [1.3886525, 103.8185184],
  [1.3899556, 103.8181402],
  [1.3907148, 103.818348],
  [1.392257, 103.8186603],
  [1.393463, 103.8189475],
  [1.3935595, 103.8187437],
  [1.3922953, 103.8183623],
  [1.3909793, 103.8153225],
  [1.3819637, 103.7947351],
  [1.357948, 103.8119628],
  [1.3556775, 103.814029],
  [1.3549977, 103.8230667],
  [1.3555689, 103.8264007],
  [1.3553812, 103.8267601],
  [1.3546357, 103.8266528],
  [1.3543193, 103.8267279],
  [1.3536543, 103.8279939],
  [1.3531395, 103.8297105],
  [1.3508227, 103.8320709],
  [1.3501094, 103.8320601],
  [1.3498466, 103.8323283],
  [1.349364, 103.8337445],
  [1.3476478, 103.8353968],
  [1.347562, 103.8359761],
  [1.3474762, 103.8362672],
  [1.3478302, 103.8369766],
  [1.348109, 103.8374608],
  [1.348227, 103.8379771],
  [1.3476049, 103.8383767],
  [1.3469507, 103.8385725],
  [1.3460041, 103.8386423],
  [1.3450093, 103.8388247],
  [1.3445561, 103.8387938],
  [1.3440171, 103.8388166],
  [1.3428533, 103.8390554],
  [1.3419785, 103.8392002],
  [1.3415357, 103.8392833],
  [1.3410822, 103.8392378],
  [1.3407651, 103.8391036],
  [1.3405017, 103.8388301],
  [1.3396731, 103.8376499],
  [1.3393353, 103.8378108],
  [1.3387239, 103.8374782],
  [1.3373725, 103.8373817],
  [1.3367075, 103.8373119],
  [1.3360639, 103.8371778],
  [1.3353478, 103.8371376],
  [1.3346317, 103.837269],
  [1.3335426, 103.8381327],
  [1.331622, 103.8389159],
  [1.3306882, 103.8392914],
  [1.3301405, 103.8398386],
  [1.329151, 103.8404179],
  [1.3287387, 103.8407384],
  [1.3293347, 103.8421318],
  [1.3295184, 103.84435],
  [1.3294352, 103.8479817]
];
// NE4 EE Tang Kah Leong
const coordinatesNE4_1 = [
  [1.3702757, 103.8766928],
  [1.3694552, 103.8802011],
  [1.3698842, 103.8802333],
  [1.3705063, 103.8803728],
  [1.3708817, 103.8806195],
  [1.3709407, 103.8812364],
  [1.3707447, 103.8821205],
  [1.3703586, 103.8834509],
  [1.3702408, 103.8841949],
  [1.3702556, 103.8847193],
  [1.3704229, 103.8848886],
  [1.370767, 103.8852604],
  [1.3712596, 103.8855752],
  [1.3716985, 103.8858743],
  [1.3720677, 103.8862456],
  [1.3727993, 103.8852082],
  [1.3743402, 103.8854869],
  [1.3750296, 103.8850327],
  [1.3756853, 103.8847627],
  [1.3769356, 103.8847349],
  [1.3791803, 103.8847103],
  [1.37888, 103.8767098],
  [1.3783385, 103.876799],
  [1.3772231, 103.8770351],
  [1.3759467, 103.8771745],
  [1.3748468, 103.8772407],
  [1.3726968, 103.8769814],
  [1.3704337, 103.8766703],
  [1.3702757, 103.8766928]
];
// NE4_2
const coordinatesNE4_2 = [
  [1.3888775, 103.86915],
  [1.3882715, 103.8694343],
  [1.3871935, 103.8738062],
  [1.3883586, 103.8770832],
  [1.3886335, 103.8787723],
  [1.3882178, 103.8805842],
  [1.3887592, 103.8808375],
  [1.3891932, 103.8811552],
  [1.3896541, 103.8813816],
  [1.3901471, 103.881533],
  [1.3910367, 103.8815246],
  [1.3914868, 103.8813971],
  [1.3918941, 103.8812158],
  [1.3888346, 103.8865789],
  [1.3872418, 103.8889687],
  [1.3867725, 103.8900296],
  [1.3864535, 103.8910904],
  [1.3998069, 103.8959318],
  [1.4007079, 103.8894207],
  [1.4010565, 103.8861568],
  [1.4010833, 103.8832362],
  [1.401086, 103.8799681],
  [1.4010028, 103.8765927],
  [1.4009197, 103.8721552],
  [1.4008245, 103.8707089],
  [1.4007079, 103.8696488],
  [1.4004075, 103.8693276],
  [1.3999142, 103.8691781],
  [1.3967394, 103.8693296],
  [1.3924706, 103.8693739],
  [1.3909475, 103.8693967],
  [1.3900143, 103.8692786],
  [1.3888775, 103.86915]
];
// NE3 EE Jason Wang Jian Zheng
const coordinatesNE3 = [
  [1.3668953, 103.8306225],
  [1.3644746, 103.8363966],
  [1.3629429, 103.8399672],
  [1.3624401, 103.841999],
  [1.3640825, 103.8422284],
  [1.363923, 103.8432731],
  [1.362605, 103.8426441],
  [1.3624173, 103.8437546],
  [1.3571885, 103.8499559],
  [1.3601863, 103.8520051],
  [1.3598337, 103.8524423],
  [1.3552185, 103.8564964],
  [1.3695053, 103.8617213],
  [1.3705028, 103.8647415],
  [1.3717577, 103.8671179],
  [1.3716344, 103.8695051],
  [1.3705376, 103.8736759],
  [1.3695267, 103.8764735],
  [1.3748575, 103.8772085],
  [1.3770028, 103.8770315],
  [1.3788907, 103.8766776],
  [1.3789765, 103.8806043],
  [1.3791482, 103.8844131],
  [1.3793262, 103.8860666],
  [1.3801194, 103.8872056],
  [1.3817387, 103.888612],
  [1.382071, 103.8883231],
  [1.3843968, 103.8902602],
  [1.3864642, 103.8910582],
  [1.3872525, 103.8889365],
  [1.3919048, 103.8811836],
  [1.3912386, 103.8814764],
  [1.3901578, 103.8815008],
  [1.3892039, 103.881123],
  [1.3882285, 103.880552],
  [1.3886442, 103.8787401],
  [1.3883693, 103.877051],
  [1.3872042, 103.873774],
  [1.3882822, 103.8694021],
  [1.3888882, 103.8691178],
  [1.3909582, 103.8693645],
  [1.3971336, 103.8692937],
  [1.3999249, 103.8691459],
  [1.4004182, 103.8692954],
  [1.4007186, 103.8696166],
  [1.4010645, 103.8779564],
  [1.4010672, 103.8861246],
  [1.403132, 103.8863855],
  [1.4046604, 103.8874404],
  [1.40687, 103.8911526],
  [1.4113318, 103.8949291],
  [1.4153646, 103.8968174],
  [1.4180246, 103.8975899],
  [1.4205129, 103.8975899],
  [1.4308952, 103.8806812],
  [1.4320964, 103.8769047],
  [1.4300371, 103.8714974],
  [1.4258327, 103.8675491],
  [1.4216283, 103.8626568],
  [1.4171665, 103.860511],
  [1.4045532, 103.8467781],
  [1.4037529, 103.8445698],
  [1.4005211, 103.8449248],
  [1.4002068, 103.8442498],
  [1.4013799, 103.8431573],
  [1.4028105, 103.8430089],
  [1.4028698, 103.8413855],
  [1.398269, 103.8395119],
  [1.3990207, 103.8341341],
  [1.4004384, 103.8353088],
  [1.4010429, 103.8355125],
  [1.4020803, 103.834804],
  [1.4020957, 103.8338592],
  [1.4041859, 103.8341583],
  [1.405792, 103.8364301],
  [1.4064302, 103.8363819],
  [1.4069625, 103.8349321],
  [1.4065723, 103.8323237],
  [1.405605, 103.8304937],
  [1.4046231, 103.8297144],
  [1.4033409, 103.8292354],
  [1.4027071, 103.8284492],
  [1.403604, 103.828008],
  [1.4077923, 103.8292901],
  [1.4098731, 103.8300411],
  [1.4101305, 103.8292257],
  [1.4053684, 103.827552],
  [1.4018075, 103.8269512],
  [1.3975172, 103.8287537],
  [1.3938276, 103.833646],
  [1.3937418, 103.8317578],
  [1.3925405, 103.8266937],
  [1.3927979, 103.8232605],
  [1.393463, 103.8189475],
  [1.3911033, 103.8183521],
  [1.3899556, 103.8181402],
  [1.3895373, 103.8182073],
  [1.3886525, 103.8185184],
  [1.3881108, 103.8189368],
  [1.3874512, 103.8198381],
  [1.3861481, 103.8219838],
  [1.3844212, 103.824607],
  [1.3896821, 103.8280483],
  [1.3892451, 103.8293827],
  [1.3878574, 103.8316806],
  [1.3840532, 103.8293481],
  [1.3833094, 103.8288095],
  [1.3821224, 103.8263086],
  [1.3794981, 103.8276915],
  [1.3785864, 103.8281315],
  [1.3773369, 103.8284694],
  [1.3751649, 103.8286249],
  [1.3724942, 103.8287536],
  [1.3709711, 103.8287429],
  [1.368051, 103.8284833],
  [1.3671688, 103.8284811],
  [1.3668953, 103.8306225]
];
// NE1 EE Qama
const coordinatesNE1 = [
  [1.3869514, 103.9158332],
  [1.384892, 103.9144532],
  [1.379044, 103.9138682],
  [1.3770705, 103.9100917],
  [1.3641137, 103.905371],
  [1.3631699, 103.9042552],
  [1.3623517, 103.9047297],
  [1.3615795, 103.9046868],
  [1.3503443, 103.9257829],
  [1.3501243, 103.9261874],
  [1.3485905, 103.9252754],
  [1.3475286, 103.923591],
  [1.3459197, 103.9199647],
  [1.344394, 103.9179611],
  [1.3400742, 103.9146539],
  [1.3387763, 103.912991],
  [1.3372103, 103.9081952],
  [1.3389908, 103.9000305],
  [1.3399294, 103.8981958],
  [1.3375269, 103.8966501],
  [1.3345236, 103.8999116],
  [1.3287745, 103.9056623],
  [1.32723, 103.9063489],
  [1.3269725, 103.9071214],
  [1.3279164, 103.909353],
  [1.3318716, 103.918269],
  [1.331076, 103.9202117],
  [1.3298942, 103.9219398],
  [1.3290986, 103.9239039],
  [1.3293327, 103.926426],
  [1.3313753, 103.9344093],
  [1.3350814, 103.9339435],
  [1.3349526, 103.938278],
  [1.3363685, 103.9403379],
  [1.3493253, 103.9557445],
  [1.3503121, 103.9575899],
  [1.3509557, 103.9600789],
  [1.350827, 103.962096],
  [1.3514705, 103.9631259],
  [1.3531437, 103.9636838],
  [1.357477, 103.9622676],
  [1.3595792, 103.9609802],
  [1.361038, 103.9614093],
  [1.3781134, 103.9784038],
  [1.3793147, 103.978318],
  [1.3866939, 103.9738548],
  [1.3873804, 103.973254],
  [1.3866939, 103.971709],
  [1.3853211, 103.9700782],
  [1.3837766, 103.967675],
  [1.3829185, 103.9670742],
  [1.3815456, 103.9673316],
  [1.3812024, 103.9663875],
  [1.381803, 103.9651859],
  [1.381803, 103.962611],
  [1.3811166, 103.9589202],
  [1.3818888, 103.9555728],
  [1.3821462, 103.9520538],
  [1.3830901, 103.9491355],
  [1.3853211, 103.945359],
  [1.3873804, 103.9426124],
  [1.3893539, 103.9395225],
  [1.3905552, 103.9374626],
  [1.3927861, 103.9365184],
  [1.3950171, 103.935231],
  [1.3981919, 103.9330852],
  [1.3994789, 103.9305961],
  [1.3987925, 103.9280212],
  [1.3973338, 103.9248455],
  [1.3953603, 103.9224422],
  [1.3932152, 103.9199531],
  [1.3915849, 103.9180648],
  [1.3889249, 103.9166915],
  [1.3869514, 103.9158332]
];
// NE1 EE Qama - SM Melissa Lin
const coordinatesNE1_2 = [
  [1.4012799, 103.8873814],
  [1.4009029, 103.8872607],
  [1.3997854, 103.8959318],
  [1.386432, 103.8910904],
  [1.3855471, 103.8907497],
  [1.3843646, 103.8902924],
  [1.3828208, 103.8890133],
  [1.3820388, 103.8883553],
  [1.3817065, 103.8886442],
  [1.3800872, 103.8872378],
  [1.379294, 103.8860988],
  [1.3791588, 103.8847103],
  [1.3769141, 103.8847349],
  [1.3756638, 103.8847627],
  [1.3750081, 103.8850327],
  [1.3743187, 103.8854869],
  [1.3739052, 103.8853654],
  [1.3727778, 103.8852082],
  [1.3722868, 103.8858874],
  [1.3748717, 103.8881297],
  [1.3759711, 103.8897015],
  [1.3764994, 103.8908844],
  [1.3769244, 103.8925916],
  [1.3770082, 103.8941319],
  [1.3769428, 103.8952239],
  [1.3766313, 103.8962419],
  [1.3771619, 103.8966222],
  [1.3782853, 103.8967051],
  [1.3790401, 103.8967465],
  [1.3792502, 103.8972075],
  [1.3798894, 103.9020887],
  [1.3802497, 103.9045238],
  [1.3797434, 103.9059345],
  [1.3784391, 103.9080345],
  [1.3764055, 103.9095767],
  [1.3790225, 103.9138682],
  [1.3857153, 103.9127524],
  [1.3870024, 103.9143832],
  [1.3886327, 103.9148982],
  [1.3926656, 103.9176448],
  [1.396012, 103.920048],
  [1.3992726, 103.9250262],
  [1.4006455, 103.9257129],
  [1.4012461, 103.9245112],
  [1.4036486, 103.9240821],
  [1.4044209, 103.9218505],
  [1.406566, 103.9186747],
  [1.4125723, 103.9150699],
  [1.4181496, 103.9142974],
  [1.4212386, 103.9127524],
  [1.4217534, 103.9092334],
  [1.4202089, 103.9070018],
  [1.4202089, 103.9035685],
  [1.4164335, 103.8991912],
  [1.4101269, 103.8957579],
  [1.4056651, 103.8916166],
  [1.4041635, 103.8884194],
  [1.4025938, 103.8874029],
  [1.4012799, 103.8873814]
];
// NE2 DyM Justin KW NG - EE Qama
const coordinatesNE2 = [
  [1.3399133, 103.8981851],
  [1.3395431, 103.8988557],
  [1.3389747, 103.9000198],
  [1.338594, 103.9017579],
  [1.3382883, 103.9034745],
  [1.3371942, 103.9081845],
  [1.3387602, 103.9129803],
  [1.3400581, 103.9146432],
  [1.3425947, 103.9163759],
  [1.3443779, 103.9179504],
  [1.3459036, 103.919954],
  [1.3475125, 103.9235803],
  [1.3485744, 103.9252647],
  [1.3501082, 103.9261767],
  [1.3615634, 103.9046761],
  [1.3623356, 103.904719],
  [1.3631538, 103.9042445],
  [1.3641137, 103.905371],
  [1.3762392, 103.9099093],
  [1.3784391, 103.9080345],
  [1.3802497, 103.9045238],
  [1.3792502, 103.8972075],
  [1.3790401, 103.8967465],
  [1.3771619, 103.8966222],
  [1.3766313, 103.8962419],
  [1.3769428, 103.8952239],
  [1.3770082, 103.8941319],
  [1.3769244, 103.8925916],
  [1.3764994, 103.8908844],
  [1.3759711, 103.8897015],
  [1.3748717, 103.8881297],
  [1.3722868, 103.8858874],
  [1.3720677, 103.8862456],
  [1.3716985, 103.8858743],
  [1.370767, 103.8852604],
  [1.3702556, 103.8847193],
  [1.3703586, 103.8834509],
  [1.3709407, 103.8812364],
  [1.3708817, 103.8806195],
  [1.3705063, 103.8803728],
  [1.3694552, 103.8802011],
  [1.3702757, 103.8766928],
  [1.3694999, 103.876495],
  [1.3716076, 103.8695266],
  [1.3717309, 103.8671394],
  [1.370476, 103.864763],
  [1.3694785, 103.8617428],
  [1.3551917, 103.8565179],
  [1.3517385, 103.8570401],
  [1.3474911, 103.8586709],
  [1.3446165, 103.8598724],
  [1.3489283, 103.8600227],
  [1.3499795, 103.86116],
  [1.3521783, 103.8628336],
  [1.3541626, 103.8645931],
  [1.3556642, 103.8676831],
  [1.3551574, 103.867734],
  [1.3553914, 103.8686735],
  [1.3555838, 103.8729885],
  [1.3555006, 103.8732889],
  [1.354975, 103.8740989],
  [1.3522949, 103.8764486],
  [1.3524785, 103.8767168],
  [1.3521487, 103.8772747],
  [1.3519905, 103.8779184],
  [1.3516379, 103.8788036],
  [1.351296, 103.879163],
  [1.3507302, 103.8795063],
  [1.350993, 103.879753],
  [1.3507866, 103.8813356],
  [1.3509019, 103.8831863],
  [1.3513417, 103.8854393],
  [1.350371, 103.8855519],
  [1.3489605, 103.8858228],
  [1.348451, 103.886374],
  [1.3453861, 103.8862419],
  [1.3444972, 103.8866694],
  [1.3437525, 103.8867759],
  [1.3412563, 103.8859064],
  [1.3424967, 103.8883255],
  [1.3434796, 103.8909593],
  [1.3434045, 103.8910505],
  [1.3430291, 103.8913295],
  [1.3431411, 103.892285],
  [1.3429825, 103.8927413],
  [1.3427167, 103.8931547],
  [1.3418037, 103.8945723],
  [1.3403208, 103.8974717],
  [1.3399133, 103.8981851]
];

// South East (SE)
const coordinatesSE = [
  [1.3446058, 103.8598402],
  [1.343663, 103.8608846],
  [1.3439847, 103.8630304],
  [1.3443923, 103.8644681],
  [1.3444567, 103.8653478],
  [1.3439204, 103.8670859],
  [1.3433197, 103.8684913],
  [1.3430194, 103.8695106],
  [1.3431857, 103.870723],
  [1.3418087, 103.8708168],
  [1.3403674, 103.8709322],
  [1.3383147, 103.8707793],
  [1.3375277, 103.8707123],
  [1.3373776, 103.8733301],
  [1.3376994, 103.8753471],
  [1.3373132, 103.8767419],
  [1.336734, 103.8775787],
  [1.3311136, 103.8789091],
  [1.3264934, 103.8802663],
  [1.32384, 103.8810415],
  [1.3211009, 103.8814304],
  [1.3181514, 103.8817694],
  [1.3151139, 103.8815528],
  [1.313135, 103.8819256],
  [1.3105611, 103.8820389],
  [1.3098103, 103.8785843],
  [1.3068982, 103.8763901],
  [1.3067373, 103.8730106],
  [1.3055896, 103.8712188],
  [1.3041262, 103.8713358],
  [1.2982649, 103.8664825],
  [1.296694, 103.8659208],
  [1.2929219, 103.8669089],
  [1.2894073, 103.8683262],
  [1.2843052, 103.8715244],
  [1.2807476, 103.8759242],
  [1.2950812, 103.8977477],
  [1.3061952, 103.9357392],
  [1.3089949, 103.9348984],
  [1.3122237, 103.9340576],
  [1.3136827, 103.933654],
  [1.3138544, 103.9333933],
  [1.3139618, 103.933111],
  [1.313907, 103.9318819],
  [1.313584, 103.9301647],
  [1.3131624, 103.9287213],
  [1.3129232, 103.9270527],
  [1.3126303, 103.9254591],
  [1.3123052, 103.9238763],
  [1.3126195, 103.9235456],
  [1.3131054, 103.9233222],
  [1.3140396, 103.9230256],
  [1.3149632, 103.922772],
  [1.3154062, 103.9226451],
  [1.315935, 103.9226256],
  [1.3163501, 103.9226252],
  [1.3167331, 103.9225282],
  [1.3172048, 103.922367],
  [1.3175478, 103.9220503],
  [1.3180141, 103.9215727],
  [1.3182766, 103.921374],
  [1.3184612, 103.9211537],
  [1.3187959, 103.9206973],
  [1.3190984, 103.9205855],
  [1.3195457, 103.9206079],
  [1.3199148, 103.9205937],
  [1.3203138, 103.9204645],
  [1.320788, 103.9204748],
  [1.3213501, 103.9199215],
  [1.3226416, 103.9237025],
  [1.3233303, 103.9271809],
  [1.3236972, 103.9271188],
  [1.3293327, 103.926426],
  [1.3290986, 103.9239039],
  [1.3298942, 103.9219398],
  [1.331076, 103.9202117],
  [1.3318716, 103.918269],
  [1.3269725, 103.9071214],
  [1.32723, 103.9063489],
  [1.3287745, 103.9056623],
  [1.3375269, 103.8966501],
  [1.3399294, 103.8981958],
  [1.3418037, 103.8945723],
  [1.3431411, 103.892285],
  [1.3430291, 103.8913295],
  [1.3434796, 103.8909593],
  [1.3412456, 103.8858742],
  [1.3437418, 103.8867437],
  [1.3444865, 103.8866372],
  [1.3453754, 103.8862097],
  [1.3484403, 103.8863418],
  [1.3489498, 103.8857906],
  [1.351331, 103.8854071],
  [1.3508912, 103.8831541],
  [1.3507759, 103.8813034],
  [1.3509823, 103.8797208],
  [1.3507195, 103.8794741],
  [1.3512853, 103.8791308],
  [1.3516272, 103.8787714],
  [1.3519798, 103.8778862],
  [1.352138, 103.8772425],
  [1.3524785, 103.8767168],
  [1.3522949, 103.8764486],
  [1.354975, 103.8740989],
  [1.3554926, 103.8733264],
  [1.3555838, 103.8729885],
  [1.3555625, 103.8720036],
  [1.3555198, 103.8710187],
  [1.3553914, 103.8686735],
  [1.3552848, 103.8682101],
  [1.3551574, 103.867734],
  [1.3556535, 103.8676509],
  [1.3541519, 103.8645609],
  [1.3499688, 103.8611278],
  [1.3489176, 103.8599905],
  [1.3446058, 103.8598402]
];

// SE2 E Siti Nuruljannah Binte Salleh Iskandar
const coordinatesSE2 = [
  [1.3353908, 103.8581111],
  [1.3330392, 103.8581493],
  [1.3330928, 103.8588644],
  [1.3320631, 103.8605559],
  [1.3314517, 103.8620901],
  [1.3300573, 103.8620579],
  [1.3298899, 103.862832],
  [1.3292678, 103.8641409],
  [1.3287422, 103.8655035],
  [1.3283025, 103.8668768],
  [1.328045, 103.8677135],
  [1.3276375, 103.8682178],
  [1.327498, 103.8684861],
  [1.3273401, 103.8687997],
  [1.3268448, 103.8695171],
  [1.3257843, 103.8707254],
  [1.3223174, 103.8734106],
  [1.3206881, 103.8744121],
  [1.3196851, 103.8759813],
  [1.3195658, 103.8764412],
  [1.3195135, 103.87876],
  [1.3206227, 103.8814143],
  [1.32384, 103.8810415],
  [1.3302012, 103.8792028],
  [1.336734, 103.8775787],
  [1.3373132, 103.8767419],
  [1.3376994, 103.8753471],
  [1.3373766, 103.8735608],
  [1.3375277, 103.8707123],
  [1.3403674, 103.8709322],
  [1.3431857, 103.870723],
  [1.3430194, 103.8695106],
  [1.3439204, 103.8670859],
  [1.3444567, 103.8653478],
  [1.3443923, 103.8644681],
  [1.3439945, 103.863186],
  [1.343663, 103.8608846],
  [1.343572, 103.8572944],
  [1.3436793, 103.8538129],
  [1.341266, 103.8535071],
  [1.3401344, 103.8531638],
  [1.3397241, 103.853888],
  [1.3397107, 103.8585993],
  [1.3393568, 103.8592886],
  [1.3390886, 103.8595313],
  [1.3387025, 103.8596876],
  [1.3356523, 103.8597219],
  [1.3354659, 103.8596041],
  [1.3353654, 103.8594111],
  [1.3353908, 103.8581111]
];

// SE2 EE Siti Nuruljannah Binte Salleh Iskandar
const coordinatesSE2_EE = [
  [1.3124761, 103.9237019],
  [1.3123052, 103.9238763],
  [1.3129277, 103.9268588],
  [1.3131636, 103.9290045],
  [1.3136785, 103.9305066],
  [1.313907, 103.9318819],
  [1.3139618, 103.933111],
  [1.3139252, 103.9332961],
  [1.3136827, 103.933654],
  [1.3061952, 103.9357392],
  [1.3099816, 103.9497088],
  [1.3134139, 103.9601801],
  [1.3135856, 103.9639567],
  [1.315645, 103.9706515],
  [1.3151301, 103.9747714],
  [1.3163314, 103.9782046],
  [1.3168463, 103.9842127],
  [1.3154733, 103.9843844],
  [1.3116978, 103.9835261],
  [1.3137572, 103.9905642],
  [1.3089519, 104.0025805],
  [1.3110113, 104.0061854],
  [1.3094668, 104.0096186],
  [1.3104965, 104.0142535],
  [1.3151301, 104.0157984],
  [1.3151301, 104.0327929],
  [1.3255987, 104.0326212],
  [1.3276581, 104.0212916],
  [1.3540867, 104.0326212],
  [1.3582054, 104.0334795],
  [1.3504828, 104.0415476],
  [1.3487666, 104.0420626],
  [1.3491099, 104.0437792],
  [1.3551163, 104.0418909],
  [1.3621525, 104.0319346],
  [1.370042, 104.014382],
  [1.3703852, 104.0138241],
  [1.3693985, 104.011893],
  [1.3690981, 104.0094897],
  [1.3705139, 104.0060994],
  [1.3723588, 104.0066144],
  [1.3725733, 104.0087601],
  [1.3739891, 104.0088889],
  [1.3745039, 104.0066573],
  [1.3847577, 104.002237],
  [1.389048, 103.9988467],
  [1.3911931, 103.992624],
  [1.3931237, 103.9871308],
  [1.3931237, 103.981466],
  [1.3916221, 103.9754578],
  [1.3893912, 103.9732691],
  [1.3873804, 103.973254],
  [1.3795292, 103.9782322],
  [1.3781134, 103.9784038],
  [1.361038, 103.9614093],
  [1.3595792, 103.9609802],
  [1.357477, 103.9622676],
  [1.3531437, 103.9636838],
  [1.3514705, 103.9631259],
  [1.350827, 103.962096],
  [1.3509557, 103.9600789],
  [1.3503121, 103.9575899],
  [1.3493253, 103.9557445],
  [1.3420103, 103.9470541],
  [1.3368082, 103.9409065],
  [1.3349526, 103.938278],
  [1.3350814, 103.9339435],
  [1.3313753, 103.9344093],
  [1.3293327, 103.926426],
  [1.3233303, 103.9271809],
  [1.3226416, 103.9237025],
  [1.3213501, 103.9199215],
  [1.320788, 103.9204748],
  [1.3204455, 103.9204618],
  [1.3199148, 103.9205937],
  [1.3190984, 103.9205855],
  [1.3187959, 103.9206973],
  [1.3186007, 103.9209768],
  [1.3182766, 103.921374],
  [1.3180141, 103.9215727],
  [1.3172048, 103.922367],
  [1.3163501, 103.9226252],
  [1.3154062, 103.9226451],
  [1.3143639, 103.9229724],
  [1.3135702, 103.9231762],
  [1.3131054, 103.9233222],
  [1.3128408, 103.9234766],
  [1.3126195, 103.9235456],
  [1.3124761, 103.9237019]
];

// SE3 AE Aisyah
const coordinatesSE3 = [
  [1.3035559, 103.8540202],
  [1.3075892, 103.8566098],
  [1.312116, 103.8595106],
  [1.3134467, 103.8598619],
  [1.3141989, 103.8604143],
  [1.3205165, 103.8651135],
  [1.3232642, 103.866884],
  [1.323737, 103.8671845],
  [1.3247027, 103.8677419],
  [1.3259287, 103.867996],
  [1.3266812, 103.8680372],
  [1.3271862, 103.8684011],
  [1.3273274, 103.8686642],
  [1.327498, 103.8684861],
  [1.328045, 103.8677135],
  [1.3284359, 103.8663852],
  [1.3291653, 103.8644165],
  [1.3298899, 103.862832],
  [1.3300412, 103.8620633],
  [1.329993, 103.8604325],
  [1.3286576, 103.8560873],
  [1.3293163, 103.8503252],
  [1.3294203, 103.8474013],
  [1.3294815, 103.8444774],
  [1.3293186, 103.8421372],
  [1.3287226, 103.8407438],
  [1.3300486, 103.8398781],
  [1.3306882, 103.8392914],
  [1.3335426, 103.8381327],
  [1.3346317, 103.837269],
  [1.3353317, 103.837143],
  [1.3372414, 103.8373634],
  [1.3387239, 103.8374782],
  [1.3393353, 103.8378108],
  [1.3396731, 103.8376499],
  [1.340749, 103.839109],
  [1.3413949, 103.8392431],
  [1.3417179, 103.8392243],
  [1.3438315, 103.8388716],
  [1.3450093, 103.8388247],
  [1.3469507, 103.8385725],
  [1.3476049, 103.8383767],
  [1.348227, 103.8379771],
  [1.3474762, 103.8362672],
  [1.3434308, 103.8354486],
  [1.3418004, 103.8293333],
  [1.3414036, 103.8237865],
  [1.3400307, 103.8205249],
  [1.3397947, 103.8195807],
  [1.3407396, 103.8167421],
  [1.3399898, 103.8120582],
  [1.3402366, 103.8103737],
  [1.3389152, 103.8077361],
  [1.3380035, 103.8051344],
  [1.3381429, 103.8020606],
  [1.3378597, 103.8011961],
  [1.3372569, 103.8000074],
  [1.3365253, 103.798969],
  [1.3336401, 103.7982421],
  [1.3325031, 103.7984808],
  [1.3311195, 103.7981106],
  [1.3301059, 103.8009216],
  [1.3293943, 103.8030146],
  [1.3291243, 103.8036159],
  [1.328003, 103.8053567],
  [1.3268816, 103.8070332],
  [1.3246068, 103.8103969],
  [1.3243442, 103.8109183],
  [1.323998, 103.8124251],
  [1.3239805, 103.8133179],
  [1.3237537, 103.8136851],
  [1.3234544, 103.8143592],
  [1.3233636, 103.8148645],
  [1.3231174, 103.8161757],
  [1.3228283, 103.8191338],
  [1.3223513, 103.8209337],
  [1.3214828, 103.8236081],
  [1.3204536, 103.8266501],
  [1.319092, 103.8307812],
  [1.3182181, 103.8334582],
  [1.3178536, 103.8345285],
  [1.3174729, 103.8351964],
  [1.3154416, 103.8370869],
  [1.3131962, 103.8389443],
  [1.3128882, 103.8392389],
  [1.3128708, 103.8396382],
  [1.3127248, 103.8397212],
  [1.3118839, 103.8417041],
  [1.3109313, 103.8437818],
  [1.3102704, 103.8453836],
  [1.3100202, 103.845812],
  [1.3096627, 103.8461332],
  [1.3088941, 103.846722],
  [1.3081078, 103.8474918],
  [1.3075862, 103.8481194],
  [1.3068113, 103.8488489],
  [1.3061328, 103.8497193],
  [1.3051702, 103.8508901],
  [1.30454, 103.8518463],
  [1.3041029, 103.852824],
  [1.3035559, 103.8540202]
];

// SE3 EE Aerielle
const coordinatesSE3_EE = [
  [1.3051755, 103.8508954],
  [1.3046197, 103.8505681],
  [1.3040754, 103.8502167],
  [1.3038368, 103.8501389],
  [1.3034131, 103.8500517],
  [1.3027964, 103.8500236],
  [1.3019972, 103.8500048],
  [1.3015226, 103.8499807],
  [1.3010507, 103.8499002],
  [1.3005304, 103.84975],
  [1.2999472, 103.8495783],
  [1.2995517, 103.849412],
  [1.2993801, 103.8493047],
  [1.2986614, 103.8487093],
  [1.2987808, 103.848425],
  [1.2988116, 103.8478215],
  [1.2987606, 103.8475426],
  [1.29864, 103.8475613],
  [1.2986749, 103.8479369],
  [1.2986668, 103.8482923],
  [1.2986333, 103.8484485],
  [1.2985569, 103.8486208],
  [1.298109, 103.8482426],
  [1.2978195, 103.8483365],
  [1.2977444, 103.8484008],
  [1.2973153, 103.8488836],
  [1.2972188, 103.8488837],
  [1.296161, 103.8496709],
  [1.2957708, 103.8501148],
  [1.2955696, 103.8501416],
  [1.2950387, 103.8497795],
  [1.2945882, 103.850544],
  [1.2938347, 103.8501068],
  [1.2932193, 103.8497849],
  [1.2929062, 103.8496266],
  [1.2925932, 103.8495113],
  [1.2928895, 103.8489642],
  [1.2928532, 103.8489266],
  [1.2925435, 103.8494885],
  [1.292146, 103.8493309],
  [1.2917766, 103.8491532],
  [1.2911552, 103.8488146],
  [1.2905445, 103.8484679],
  [1.2900082, 103.8479931],
  [1.2898995, 103.8482936],
  [1.2890495, 103.8476096],
  [1.2868572, 103.8458014],
  [1.2858985, 103.8449954],
  [1.2848844, 103.8441563],
  [1.2837115, 103.8461136],
  [1.2829365, 103.8473796],
  [1.2822741, 103.8485143],
  [1.2819435, 103.8490657],
  [1.2817256, 103.8495233],
  [1.2824758, 103.8500369],
  [1.2824674, 103.8501073],
  [1.2823901, 103.8502689],
  [1.2822431, 103.850326],
  [1.2818837, 103.850336],
  [1.2816746, 103.8503384],
  [1.2814949, 103.8503154],
  [1.2813327, 103.8502208],
  [1.2812757, 103.850321],
  [1.2814751, 103.8505105],
  [1.2819878, 103.8508575],
  [1.2825327, 103.851199],
  [1.2825876, 103.851254],
  [1.2825849, 103.85136],
  [1.2818261, 103.8508919],
  [1.2815921, 103.8512681],
  [1.2814627, 103.8514505],
  [1.2799918, 103.8505297],
  [1.2799502, 103.8506101],
  [1.2803809, 103.8508948],
  [1.2808251, 103.8511526],
  [1.2824186, 103.8522262],
  [1.2825473, 103.8523147],
  [1.2821263, 103.8530068],
  [1.282251, 103.8531684],
  [1.2823542, 103.853389],
  [1.2824213, 103.8538731],
  [1.2834778, 103.8539281],
  [1.284545, 103.8541333],
  [1.2868847, 103.8548166],
  [1.2889401, 103.8551565],
  [1.2885486, 103.8606496],
  [1.2884493, 103.8618244],
  [1.2884573, 103.8638361],
  [1.2885163, 103.8642894],
  [1.2888328, 103.8647749],
  [1.2893021, 103.8650083],
  [1.2902272, 103.8650217],
  [1.2937668, 103.8645281],
  [1.2942675, 103.8644692],
  [1.2955726, 103.8643029],
  [1.2962201, 103.8643889],
  [1.2966247, 103.8643784],
  [1.2966294, 103.8640676],
  [1.297621, 103.8641485],
  [1.2983766, 103.8643582],
  [1.2989933, 103.8647002],
  [1.2995135, 103.8651065],
  [1.300259, 103.8659621],
  [1.3008154, 103.8667001],
  [1.3012753, 103.8671859],
  [1.3013584, 103.8675138],
  [1.3017499, 103.867719],
  [1.3019215, 103.8675715],
  [1.3023935, 103.8676627],
  [1.3030719, 103.8677378],
  [1.3031711, 103.8682206],
  [1.3033695, 103.8683493],
  [1.3038308, 103.8680168],
  [1.3046625, 103.8692544],
  [1.3043031, 103.8694529],
  [1.3041262, 103.8713358],
  [1.3047376, 103.8713251],
  [1.3055896, 103.8712188],
  [1.306748, 103.8730428],
  [1.3069089, 103.8764223],
  [1.3076865, 103.8772807],
  [1.3081156, 103.8775274],
  [1.3082818, 103.8771734],
  [1.3091586, 103.877801],
  [1.309821, 103.8786165],
  [1.3101857, 103.880384],
  [1.3105289, 103.8817599],
  [1.3105718, 103.8820711],
  [1.3131457, 103.8819578],
  [1.3148296, 103.8816977],
  [1.3151246, 103.881585],
  [1.3165056, 103.8816735],
  [1.3181621, 103.8818016],
  [1.3207149, 103.8815924],
  [1.3195135, 103.87876],
  [1.3195431, 103.8769012],
  [1.3195658, 103.8764412],
  [1.3196851, 103.8759813],
  [1.3201115, 103.8751538],
  [1.3206881, 103.8744121],
  [1.3221816, 103.8734224],
  [1.3242545, 103.871864],
  [1.3257843, 103.8707254],
  [1.3262415, 103.870252],
  [1.3268448, 103.8695171],
  [1.3272081, 103.8690424],
  [1.3273401, 103.8687997],
  [1.3273274, 103.8686642],
  [1.3270524, 103.8683112],
  [1.326868, 103.8682515],
  [1.326567, 103.8680859],
  [1.3252598, 103.8679397],
  [1.3247027, 103.8677419],
  [1.3241832, 103.8674905],
  [1.3192385, 103.8641752],
  [1.3134467, 103.8598619],
  [1.312116, 103.8595106],
  [1.3077448, 103.8567788],
  [1.3035559, 103.8540202],
  [1.3046044, 103.8517202],
  [1.3051755, 103.8508954]
];

// North West (NW)
const coordinatesNW = [
  [1.3357878, 103.7600599],
  [1.3351812, 103.7598004],
  [1.3324353, 103.7641349],
  [1.3301614, 103.7638774],
  [1.3279304, 103.7638345],
  [1.3236829, 103.7639632],
  [1.3239794, 103.7630646],
  [1.3242564, 103.7620674],
  [1.3234492, 103.7608671],
  [1.3233634, 103.7598586],
  [1.3200491, 103.7621516],
  [1.3185375, 103.7630649],
  [1.3175224, 103.7638157],
  [1.3166918, 103.7642515],
  [1.3158184, 103.7645801],
  [1.3161203, 103.7651406],
  [1.3166946, 103.7658761],
  [1.3170914, 103.766078],
  [1.3181721, 103.766127],
  [1.3188841, 103.7662695],
  [1.3195317, 103.7666051],
  [1.3213778, 103.768311],
  [1.32141, 103.7684504],
  [1.3187694, 103.7709713],
  [1.3142946, 103.7773868],
  [1.312986, 103.778846],
  [1.3125279, 103.7811723],
  [1.3112124, 103.7844317],
  [1.3083113, 103.7883117],
  [1.3081327, 103.7886569],
  [1.30816, 103.7890229],
  [1.3080904, 103.7897565],
  [1.3079797, 103.7903743],
  [1.3071271, 103.7925455],
  [1.3060601, 103.7939871],
  [1.3032085, 103.7971374],
  [1.299986, 103.8007618],
  [1.2995846, 103.8010387],
  [1.3005226, 103.8011788],
  [1.3059714, 103.8021014],
  [1.3149599, 103.805127],
  [1.3185209, 103.8090537],
  [1.3199367, 103.811693],
  [1.3215496, 103.8132452],
  [1.3231799, 103.8135885],
  [1.3237537, 103.8136851],
  [1.3239805, 103.8133179],
  [1.323998, 103.8124251],
  [1.3243442, 103.8109183],
  [1.3246282, 103.8103647],
  [1.3265112, 103.8075667],
  [1.3283941, 103.804758],
  [1.3291243, 103.8036159],
  [1.3293604, 103.8031103],
  [1.3301327, 103.8008357],
  [1.3311195, 103.7981106],
  [1.3325031, 103.7984808],
  [1.3336401, 103.7982421],
  [1.3365253, 103.798969],
  [1.3381429, 103.8020606],
  [1.3380035, 103.8051344],
  [1.3388064, 103.8072992],
  [1.3402366, 103.8103737],
  [1.3549977, 103.8230667],
  [1.3556775, 103.814029],
  [1.357948, 103.8119628],
  [1.3819637, 103.7947351],
  [1.3922953, 103.8183623],
  [1.3935595, 103.8187437],
  [1.3946103, 103.8166491],
  [1.3952883, 103.8149371],
  [1.4049245, 103.8096032],
  [1.40464, 103.8108352],
  [1.4031785, 103.8118374],
  [1.4019315, 103.8130542],
  [1.402312, 103.8150372],
  [1.4025666, 103.8162003],
  [1.4022849, 103.8167626],
  [1.402027, 103.817188],
  [1.4024556, 103.8174418],
  [1.4028576, 103.8163787],
  [1.4028013, 103.8158901],
  [1.4022154, 103.8135215],
  [1.4030023, 103.8124832],
  [1.4047932, 103.8111724],
  [1.4051145, 103.8102675],
  [1.405757, 103.8094017],
  [1.4085986, 103.8076189],
  [1.4122125, 103.8065227],
  [1.4142517, 103.8056842],
  [1.4156025, 103.8042782],
  [1.4167387, 103.802486],
  [1.4161367, 103.7991161],
  [1.414727, 103.794471],
  [1.4133572, 103.7906464],
  [1.412329, 103.7863845],
  [1.4113009, 103.7832813],
  [1.4111216, 103.7808622],
  [1.4112854, 103.777735],
  [1.410412, 103.771781],
  [1.4103476, 103.7671891],
  [1.410605, 103.7633267],
  [1.4121066, 103.7598077],
  [1.4117202, 103.7554117],
  [1.4093391, 103.7554147],
  [1.4074728, 103.7548598],
  [1.4045125, 103.7540076],
  [1.4008228, 103.752786],
  [1.3983345, 103.7524166],
  [1.3959319, 103.7528197],
  [1.3933578, 103.7532412],
  [1.3898933, 103.7540304],
  [1.3863753, 103.7561762],
  [1.3833078, 103.7579787],
  [1.3783739, 103.7605429],
  [1.3766354, 103.7614073],
  [1.3748754, 103.7621214],
  [1.3713877, 103.7629274],
  [1.370231, 103.7634188],
  [1.369203, 103.7640391],
  [1.3674565, 103.7652708],
  [1.36624, 103.7660477],
  [1.3654954, 103.7663739],
  [1.3640826, 103.7668083],
  [1.3632783, 103.7668244],
  [1.362152, 103.7665079],
  [1.361294, 103.7663469],
  [1.3599639, 103.7664006],
  [1.3589289, 103.7667762],
  [1.3581727, 103.7673019],
  [1.3572234, 103.7674735],
  [1.3561804, 103.7682782],
  [1.3542577, 103.7690184],
  [1.3520322, 103.7697534],
  [1.3499031, 103.7699841],
  [1.3487099, 103.7702496],
  [1.3497208, 103.7721835],
  [1.3490129, 103.7727199],
  [1.347243, 103.7740932],
  [1.3466129, 103.7745277],
  [1.3460258, 103.7749836],
  [1.3443739, 103.7762175],
  [1.3423091, 103.7777786],
  [1.3409845, 103.7787817],
  [1.340088, 103.779366],
  [1.3397011, 103.7795212],
  [1.3393079, 103.779531],
  [1.3387736, 103.7794489],
  [1.337689, 103.7791022],
  [1.3380724, 103.7780548],
  [1.338236, 103.7778228],
  [1.3384586, 103.7775908],
  [1.3399575, 103.776467],
  [1.3406198, 103.7761156],
  [1.3405501, 103.7758098],
  [1.3404214, 103.775445],
  [1.3404214, 103.7745653],
  [1.3404214, 103.7733207],
  [1.3405555, 103.7725858],
  [1.3412151, 103.7718616],
  [1.3410435, 103.771647],
  [1.3408934, 103.7713037],
  [1.3405286, 103.7705366],
  [1.3403785, 103.7697802],
  [1.339928, 103.7689648],
  [1.3395633, 103.7682782],
  [1.3396277, 103.767613],
  [1.3397564, 103.7667118],
  [1.3397564, 103.7656174],
  [1.3396492, 103.7646518],
  [1.3393488, 103.7637721],
  [1.3393059, 103.763064],
  [1.3392201, 103.7626348],
  [1.3388125, 103.7623773],
  [1.3382548, 103.7621627],
  [1.3379545, 103.7617336],
  [1.3376756, 103.7605963],
  [1.3367103, 103.7604032],
  [1.3357878, 103.7600599]
];

//NW2 EE Tan Meiting
const coordinatesNW2 = [
  [1.4268006, 103.763198],
  [1.425834, 103.7618448],
  [1.4248122, 103.7604754],
  [1.4243696, 103.759621],
  [1.4237632, 103.757676],
  [1.4233872, 103.7565542],
  [1.4219594, 103.7556892],
  [1.4204457, 103.7548671],
  [1.4182094, 103.7541106],
  [1.4154529, 103.754572],
  [1.4135009, 103.7549797],
  [1.4117202, 103.7554117],
  [1.4121066, 103.7598077],
  [1.4114201, 103.7618676],
  [1.410605, 103.7633267],
  [1.4105192, 103.7659875],
  [1.4103476, 103.7671891],
  [1.4103476, 103.7711373],
  [1.410412, 103.771781],
  [1.4146486, 103.7716738],
  [1.4188262, 103.7717059],
  [1.4196279, 103.7720224],
  [1.4207729, 103.7726823],
  [1.4232183, 103.7737122],
  [1.4245053, 103.775858],
  [1.4260498, 103.7801066],
  [1.4262643, 103.7851277],
  [1.4247198, 103.7900201],
  [1.4218454, 103.795363],
  [1.4188851, 103.8006201],
  [1.4142517, 103.8056842],
  [1.4076447, 103.808302],
  [1.4051135, 103.8096377],
  [1.4051145, 103.8102675],
  [1.4047932, 103.8111724],
  [1.4030023, 103.8124832],
  [1.4022154, 103.8135215],
  [1.4028013, 103.8158901],
  [1.4028576, 103.8163787],
  [1.4024556, 103.8174418],
  [1.402027, 103.817188],
  [1.4025666, 103.8162003],
  [1.4019315, 103.8130542],
  [1.4027288, 103.8121158],
  [1.40464, 103.8108352],
  [1.4048767, 103.8100192],
  [1.402614, 103.8116712],
  [1.4000081, 103.8130227],
  [1.3965759, 103.8142672],
  [1.3953317, 103.8154259],
  [1.3935595, 103.8187437],
  [1.3925298, 103.8267259],
  [1.3937311, 103.83179],
  [1.3938169, 103.8336782],
  [1.3975065, 103.8287859],
  [1.4017968, 103.8269834],
  [1.4056712, 103.8276139],
  [1.4101198, 103.8292579],
  [1.4098624, 103.8300733],
  [1.4100473, 103.8350382],
  [1.4096182, 103.8364974],
  [1.4096182, 103.8389865],
  [1.4073873, 103.8421622],
  [1.4083312, 103.8454238],
  [1.4106479, 103.8486853],
  [1.412364, 103.8490287],
  [1.4146807, 103.8513461],
  [1.4170832, 103.8525477],
  [1.4192284, 103.8541785],
  [1.4214593, 103.8562384],
  [1.4240334, 103.8569251],
  [1.4274656, 103.8581267],
  [1.4284094, 103.8598433],
  [1.4307261, 103.8604441],
  [1.432528, 103.8627616],
  [1.4372472, 103.8624182],
  [1.4411084, 103.8585559],
  [1.4438541, 103.8564101],
  [1.4475436, 103.8553801],
  [1.4506325, 103.852891],
  [1.4524344, 103.8521186],
  [1.4559523, 103.8502303],
  [1.4592986, 103.8450804],
  [1.4607573, 103.8423339],
  [1.4611005, 103.8412181],
  [1.464361, 103.8367549],
  [1.46479, 103.8343516],
  [1.4688227, 103.827056],
  [1.4688227, 103.8224211],
  [1.4705388, 103.8175288],
  [1.4701098, 103.8110915],
  [1.4710536, 103.8094607],
  [1.4688227, 103.8027659],
  [1.4604141, 103.7884322],
  [1.4552659, 103.7823382],
  [1.4519625, 103.7774029],
  [1.4514906, 103.7773171],
  [1.4511045, 103.7755147],
  [1.4503322, 103.7731114],
  [1.448702, 103.7710944],
  [1.4487449, 103.7698498],
  [1.4467714, 103.7676182],
  [1.4449695, 103.7668887],
  [1.4432534, 103.7676612],
  [1.4399071, 103.7680903],
  [1.4400358, 103.7678543],
  [1.4352523, 103.7629834],
  [1.4345658, 103.7625328],
  [1.4330428, 103.7620607],
  [1.4319703, 103.7618461],
  [1.4311766, 103.7618247],
  [1.4298359, 103.7620178],
  [1.4288599, 103.7621573],
  [1.4285274, 103.7623075],
  [1.427487, 103.7627152],
  [1.4268006, 103.763198]
];

// NW3/CW PAE Fazly Kamis
const coordinatesNW3 = [
  [1.2917272, 103.8156842],
  [1.2908262, 103.8246535],
  [1.2907619, 103.8260053],
  [1.2918988, 103.8258336],
  [1.2928642, 103.8258122],
  [1.292891, 103.8261501],
  [1.2951167, 103.8292669],
  [1.2955457, 103.8304309],
  [1.2926496, 103.8316245],
  [1.2920704, 103.8320248],
  [1.2913089, 103.8326826],
  [1.2907725, 103.8330293],
  [1.2902041, 103.8332901],
  [1.2901605, 103.833561],
  [1.2900526, 103.8338427],
  [1.2897945, 103.8343496],
  [1.2896115, 103.8348244],
  [1.2893621, 103.8359402],
  [1.2890859, 103.8368038],
  [1.288992, 103.8379358],
  [1.2895337, 103.8381557],
  [1.2894077, 103.8390059],
  [1.2896524, 103.8394223],
  [1.2901116, 103.8397315],
  [1.2905195, 103.8399933],
  [1.2909006, 103.8410169],
  [1.2908098, 103.8419386],
  [1.2912553, 103.8438097],
  [1.2905768, 103.8446466],
  [1.2898515, 103.8447378],
  [1.2894512, 103.8449336],
  [1.2894549, 103.8453963],
  [1.2899716, 103.8462713],
  [1.2901548, 103.8470973],
  [1.2900162, 103.8480199],
  [1.290688, 103.8485289],
  [1.2918531, 103.8491666],
  [1.2932193, 103.8497849],
  [1.2939172, 103.8501564],
  [1.2945909, 103.8505494],
  [1.2950414, 103.8497849],
  [1.2955723, 103.850147],
  [1.2957735, 103.8501202],
  [1.2962107, 103.8496463],
  [1.2972215, 103.8488891],
  [1.297318, 103.848889],
  [1.2978222, 103.8483419],
  [1.2981117, 103.848248],
  [1.2985596, 103.8486262],
  [1.298636, 103.8484539],
  [1.2986427, 103.8475667],
  [1.2987633, 103.847548],
  [1.2988172, 103.8478653],
  [1.2987835, 103.8484304],
  [1.2986641, 103.8487147],
  [1.2993828, 103.8493101],
  [1.2995927, 103.8494311],
  [1.3000051, 103.8496076],
  [1.3015253, 103.8499861],
  [1.303003, 103.8500367],
  [1.3035071, 103.8501065],
  [1.3040781, 103.8502221],
  [1.3051729, 103.8508955],
  [1.3069984, 103.8487264],
  [1.3075889, 103.8481248],
  [1.3085215, 103.8471599],
  [1.3095512, 103.8462802],
  [1.3100229, 103.8458174],
  [1.3127275, 103.8397266],
  [1.3128735, 103.8396436],
  [1.3128882, 103.8392389],
  [1.313552, 103.8386574],
  [1.3155554, 103.8369859],
  [1.3174729, 103.8351964],
  [1.3178536, 103.8345285],
  [1.3197125, 103.8289195],
  [1.3218126, 103.8225728],
  [1.322122, 103.8214594],
  [1.3228374, 103.8189917],
  [1.32312, 103.8157301],
  [1.3234544, 103.8143592],
  [1.3237537, 103.8136851],
  [1.3215496, 103.8132452],
  [1.3199367, 103.811693],
  [1.3185209, 103.8090537],
  [1.3149599, 103.805127],
  [1.3059714, 103.8021014],
  [1.3003027, 103.8011251],
  [1.2973597, 103.8045103],
  [1.2960549, 103.8054819],
  [1.2937846, 103.806947],
  [1.292505, 103.8081391],
  [1.2927923, 103.8094101],
  [1.2922942, 103.8118877],
  [1.2919463, 103.8144297],
  [1.2917272, 103.8156842]
];
// Untitled layer
const coordinatesUnt = [
  [1.3003027, 103.8011251],
  [1.2949268, 103.798496],
  [1.2856406, 103.799306],
  [1.2837153, 103.809772],
  [1.2806959, 103.8175558],
  [1.2711174, 103.8111829],
  [1.2673096, 103.8171052],
  [1.2656907, 103.823704],
  [1.2720398, 103.8324367],
  [1.2721149, 103.847103],
  [1.2733537, 103.8515592],
  [1.2647513, 103.8608933],
  [1.276636, 103.8715363],
  [1.2817631, 103.8706994],
  [1.2857103, 103.863082],
  [1.2884573, 103.8638361],
  [1.2982649, 103.8664825],
  [1.3105611, 103.8820389],
  [1.3211009, 103.8814304],
  [1.3376994, 103.8753471],
  [1.3383147, 103.8707793],
  [1.3430194, 103.8695106],
  [1.343572, 103.8572944],
  [1.3582362, 103.8570962],
  [1.3694946, 103.8617535],
  [1.3704921, 103.8647737],
  [1.369516, 103.8765057],
  [1.3743402, 103.8854869],
  [1.3998069, 103.8959318],
  [1.4007079, 103.8894207],
  [1.4031213, 103.8864177],
  [1.4080035, 103.8948617],
  [1.4205022, 103.8976221],
  [1.4320857, 103.8769369],
  [1.425822, 103.8675813],
  [1.4145247, 103.8570962],
  [1.4045425, 103.8468103],
  [1.4013692, 103.8431895],
  [1.4046124, 103.8297466],
  [1.3937311, 103.83179],
  [1.3935595, 103.8187437],
  [1.4100402, 103.8054697],
  [1.4156152, 103.801391],
  [1.4094456, 103.7726145],
  [1.374459, 103.7778056],
  [1.3576839, 103.7828374],
  [1.3464003, 103.7999177],
  [1.3389152, 103.8077361],
  [1.3336401, 103.7982421],
  [1.3234379, 103.8122219],
  [1.3149599, 103.805127],
  [1.3003027, 103.8011251]
];

// East
const coordinatesEast = [
  [1.2808406, 103.8714075],
  [1.2964547, 103.9041314],
  [1.315645, 103.9706515],
  [1.3122435, 103.9865288],
  [1.3094668, 104.0096186],
  [1.3151301, 104.0157984],
  [1.3151301, 104.0327929],
  [1.3255987, 104.0326212],
  [1.3276581, 104.0212916],
  [1.3616685, 104.0352807],
  [1.3693985, 104.011893],
  [1.3705139, 104.0060994],
  [1.389048, 103.9988467],
  [1.3931237, 103.981466],
  [1.3866939, 103.9738548],
  [1.3791729, 103.9635262],
  [1.3853211, 103.945359],
  [1.3927861, 103.9365184],
  [1.4031984, 103.9302239],
  [1.4128086, 103.9233575],
  [1.4212601, 103.9127524],
  [1.4193297, 103.9013848],
  [1.4113211, 103.8949613],
  [1.4046497, 103.8874726],
  [1.4007079, 103.8894207],
  [1.3998069, 103.8959318],
  [1.3743402, 103.8854869],
  [1.369516, 103.8765057],
  [1.3694946, 103.8617535],
  [1.3582362, 103.8570962],
  [1.343572, 103.8572944],
  [1.3439204, 103.8670859],
  [1.3403674, 103.8709322],
  [1.3373776, 103.8733301],
  [1.3181514, 103.8817694],
  [1.313135, 103.8819256],
  [1.2982649, 103.8664825],
  [1.2857103, 103.863082],
  [1.2808406, 103.8714075]
];

// West
const coordinatesWest = [
  [1.4055943, 103.8305259],
  [1.4077816, 103.8293223],
  [1.4096182, 103.8364974],
  [1.4083312, 103.8454238],
  [1.432528, 103.8627616],
  [1.4406091, 103.8684258],
  [1.464361, 103.8367549],
  [1.4701098, 103.8110915],
  [1.4608587, 103.7849984],
  [1.448702, 103.7710944],
  [1.4395795, 103.7585625],
  [1.437177, 103.7448296],
  [1.4412955, 103.7341866],
  [1.4496931, 103.73091],
  [1.450208, 103.7152888],
  [1.4406091, 103.7012276],
  [1.4339053, 103.6902262],
  [1.427899, 103.675635],
  [1.4155543, 103.6662087],
  [1.4066195, 103.6636187],
  [1.3853398, 103.6584689],
  [1.3826052, 103.6723885],
  [1.3726517, 103.6730752],
  [1.3709356, 103.6596856],
  [1.368533, 103.6490426],
  [1.3623549, 103.6459527],
  [1.3274662, 103.6292001],
  [1.2717417, 103.612307],
  [1.2360447, 103.6054406],
  [1.2194725, 103.6051818],
  [1.2102049, 103.642604],
  [1.2209419, 103.6432061],
  [1.2192943, 103.6158545],
  [1.2418798, 103.6188302],
  [1.2481267, 103.644522],
  [1.2556094, 103.6469826],
  [1.2545797, 103.6212334],
  [1.2624742, 103.6202035],
  [1.2621996, 103.6433203],
  [1.2830685, 103.6524758],
  [1.3124836, 103.6354239],
  [1.3167741, 103.6374838],
  [1.2902765, 103.6648354],
  [1.2950818, 103.6761651],
  [1.2999878, 103.6915035],
  [1.2995438, 103.7060341],
  [1.307095, 103.7149605],
  [1.3001272, 103.7282927],
  [1.2981709, 103.7468896],
  [1.2940521, 103.7582192],
  [1.2875306, 103.7668023],
  [1.2820388, 103.7698922],
  [1.2855397, 103.7530119],
  [1.2805198, 103.7500937],
  [1.2680774, 103.7706931],
  [1.2783905, 103.7769048],
  [1.2726413, 103.7866037],
  [1.2593408, 103.7782781],
  [1.2548787, 103.7854021],
  [1.2703674, 103.7952297],
  [1.2659482, 103.8010233],
  [1.25355, 103.8220772],
  [1.2442825, 103.8286004],
  [1.2405068, 103.8433633],
  [1.252177, 103.8485131],
  [1.2652202, 103.8519463],
  [1.2731821, 103.8502503],
  [1.2709967, 103.8310554],
  [1.2657757, 103.8249158],
  [1.2711174, 103.8111829],
  [1.2806959, 103.8175558],
  [1.2856406, 103.799306],
  [1.295911, 103.79782],
  [1.3059714, 103.8021014],
  [1.3149599, 103.805127],
  [1.3246068, 103.8103969],
  [1.3336401, 103.7982421],
  [1.3389152, 103.8077361],
  [1.35541, 103.7852836],
  [1.357893, 103.7805352],
  [1.4094456, 103.7726145],
  [1.4156152, 103.801391],
  [1.3935595, 103.8187437],
  [1.3938169, 103.8336782],
  [1.4055943, 103.8305259]
];

const OneMap = ({
  isVisualization,
  dragLocation,
  dragLocationB,
  setDragLocation,
  setDragLocationB,
  dataLocationOneMap,
  fetchData,
  geofencing
}: {
  isVisualization: boolean;
  dragLocation: number[];
  dragLocationB: number[];
  setDragLocation: any;
  setDragLocationB: any;
  dataLocationOneMap: any[];
  fetchData: any;
  geofencing: string[] | undefined;
}) => {
  const dispatch = useAppDispatch();
  const mapRef = useRef<L.Map>(null);
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const listDetectedLocation = useAppSelector(selectDetectedLocationList);
  // const allDetectedLocation = useAppSelector(selectDetectedLocationList);
  const route = useAppSelector(selectRoute);
  const listLatLng = useAppSelector(selectLatLng);
  const searchValue = useAppSelector(selectDetectedLocationSearch);
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const selectedLocationB = useAppSelector(selectSelectedLocationB);
  const centerPosition: LatLngExpression = selectedLocation ? [selectedLocation.LATITUDE, selectedLocation.LONGITUDE] : [1.331229601, 103.903206301];
  const [zoom, setZoom] = useState<number>(16);
  const prevDetectedLocationDetailId = useRef(detectedLocationDetail?.id);
  const socket = useRef<any>(null);
  const [hoveredPolyline, setHoveredPolyline] = useState<string | null>(null);

  // const [allDetectedLocation, setAllDetectedLocation] = useState<any[]>([]);

  useEffect(() => {
    if (selectedLocation) {
      mapRef.current?.flyTo([selectedLocation.LATITUDE, selectedLocation.LONGITUDE], 18);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (searchValue?.latitude && searchValue.longitude) mapRef.current?.flyTo([searchValue?.latitude, searchValue?.longitude], 18);
  }, [searchValue]);

  const renderCircleCondition = selectedLocation && !selectedLocationB;
  const radiusInMeters: number = searchValue?.radius ? searchValue?.radius : 0;

  const PolylineMeasure = () => {
    const map = useMap();
    useEffect(() => {
      const polylineMeasure = (L.control as any).polylineMeasure({
        position: 'topleft',
        unit: 'kilometres',
        showBearings: true,
        clearMeasurementsOnStop: false,
        showClearControl: true,
        showUnitControl: true
      });
      polylineMeasure.addTo(map);
      return () => {
        polylineMeasure.remove();
      };
    }, []);
    return null;
  };

  const PolylineLayer = ({ encodedRoute }: any) => {
    const map = useMap();

    useEffect(() => {
      if (!encodedRoute) {
        console.error('Encoded route data is not provided');
        return;
      }

      let test = L.polyline((L as any).PolylineUtil.decode(encodedRoute, 5));

      const route = (L as any).Polyline.fromEncoded(encodedRoute, {
        color: 'blue',
        weight: 5,
        opacity: 0.7
      }).addTo(map);

      return () => {
        if (map && route) {
          map.removeLayer(route);
        }
      };
    }, [map, encodedRoute]);

    return null;
  };

  const MapChildComponent = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setZoom(mapEvents.getZoom());
      }
    });
    return null;
  };

  // useEffect(() => {
  //   localStorage.setItem('zoom', String(zoom));
  //   // if (zoom < 25) {
  //   //   dispatch(setDetectedLocationDetail(null));
  //   // }
  // }, [zoom]);

  const AddMarkersToMap = () => {
    const map = useMap();
    map.setMaxZoom(25);

    useEffect(() => {
      if (dataLocationOneMap) {
        let markerClusterGroup: any;
        let markers: any[] = [];
        let detectedLocationMarker: any;

        if (dataLocationOneMap.length > 500) {
          markerClusterGroup = L.markerClusterGroup({
            disableClusteringAtZoom: 25
          });
        }

        dataLocationOneMap.forEach((location) => {
          const marker = L.marker([location.latitude, location.longitude], {
            icon: location.id == detectedLocationDetail?.id ? LocationDetail : DefaultIcon
          });

          marker.on('click', () => {
            const selectedLocation = listDetectedLocation?.find((item) => item.id === location.id);
            if (selectedLocation) {
              dispatch(setDetectedLocationDetail(selectedLocation));
            } else {
              fetchData(10, 1, searchValue?.orderBy, searchValue?.order, null, location.id);
              dispatch(setDetectedLocationDetail(location));
            }
          });

          if (location.id == detectedLocationDetail?.id) {
            detectedLocationMarker = marker;
          } else if (markerClusterGroup) {
            markerClusterGroup.addLayer(marker);
          } else {
            markers.push(marker);
          }
        });

        if (markerClusterGroup) {
          map.addLayer(markerClusterGroup);
        } else {
          markers.forEach((marker) => {
            map.addLayer(marker);
          });
        }

        if (detectedLocationMarker) {
          map.addLayer(detectedLocationMarker);
        }

        return () => {
          if (markerClusterGroup) {
            map.removeLayer(markerClusterGroup);
          } else {
            markers.forEach((marker) => map.removeLayer(marker));
          }

          if (detectedLocationMarker) {
            map.removeLayer(detectedLocationMarker);
          }
        };
      }
    }, [dataLocationOneMap, detectedLocationDetail, map]);

    useEffect(() => {
      if (prevDetectedLocationDetailId.current === detectedLocationDetail?.id) {
        return;
      }
      if (dataLocationOneMap && detectedLocationDetail && dataLocationOneMap.length > 500) {
        const zoomOutLevel = 13;
        const zoomInLevel = 18;
        const duration = 2;
        // map.flyTo(map.getCenter(), zoomOutLevel, { duration: duration });
        // localStorage.setItem('zoom', String(zoomOutLevel));
        prevDetectedLocationDetailId.current = detectedLocationDetail?.id;
      }
    }, [detectedLocationDetail?.id]);

    return null;
  };

  const mapMarkers = listLatLng?.map((latLng, i) => <CircleMarker key={i} center={latLng} radius={4} color="green" />);
  return (
    <div className="d-flex position-relative z-index-0 h-100 flex-column min-height-sm-300">
      <div className="h-100">
        <MapContainer center={centerPosition} zoom={zoom} scrollWheelZoom={true} ref={mapRef} className="w-100 h-100">
          <AddMarkersToMap />
          <MapChildComponent />

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Default">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Default/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Origin">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Original/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
                detectRetina={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Grey">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Grey/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
                detectRetina={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Night">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Night/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
                detectRetina={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Satellite/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
          <ScaleControl position="bottomleft" maxWidth={240} metric={true} imperial={false} />
          {selectedLocation && !selectedLocationB && radiusInMeters && (
            <Marker icon={SelectedLocation} position={[selectedLocation.LATITUDE, selectedLocation.LONGITUDE]}></Marker>
          )}

          {renderCircleCondition && <Circle center={[selectedLocation.LATITUDE, selectedLocation.LONGITUDE]} radius={radiusInMeters} />}

          {listLatLng && listLatLng.length && (
            <>
              <Polyline positions={listLatLng} pathOptions={{ color: '#565e6b' }} opacity={0.5} />
              {mapMarkers}
            </>
          )}
          {isVisualization && detectedLocationDetail && (
            <Marker icon={SelectedLocation} position={[detectedLocationDetail.latitude, detectedLocationDetail.longitude]}></Marker>
          )}
          <PolylineMeasure />
          {dragLocation && dragLocation.length && route && (
            <DraggableMarker
              initialPosition={dragLocation}
              onPositionChange={setDragLocation}
              tooltip={route.route_summary?.start_point}
              location={'A'}
              icon={MakerIconBlue}
            />
          )}
          {dragLocationB && dragLocationB.length && route && (
            <DraggableMarker
              initialPosition={dragLocationB}
              onPositionChange={setDragLocationB}
              tooltip={route.route_summary?.end_point}
              location={'B'}
              icon={MakerIconRed}
            />
          )}
          {route && <PolylineLayer encodedRoute={route.route_geometry} />}
          {geofencing?.some((item) => ['0', '00'].includes(item)) && (
            <Polyline
              positions={coordinatesSW3 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: 'lightcoral',
                weight: hoveredPolyline === 'SW3' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SW3' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SW3'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['0', '01'].includes(item)) && (
            <Polyline
              positions={coordinatesSW2 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: 'firebrick',
                weight: hoveredPolyline === 'SW2' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SW2' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SW2'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['0', '02'].includes(item)) && (
            <Polyline
              positions={coordinatesSW1 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#2962ff',
                weight: hoveredPolyline === 'SW1' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SW1' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SW1'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '10'].includes(item)) && (
            <Polyline
              positions={coordinatesNE1 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#98494f',
                weight: hoveredPolyline === 'NE1' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE1' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE1'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '10'].includes(item)) && (
            <Polyline
              positions={coordinatesNE1_2 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#98494f',
                weight: hoveredPolyline === 'NE1_2' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE1_2' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE1_2'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '11'].includes(item)) && (
            <Polyline
              positions={coordinatesNE2 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: 'limegreen',
                weight: hoveredPolyline === 'NE2' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE2' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE2'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '12'].includes(item)) && (
            <Polyline
              positions={coordinatesNE3 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: 'yellowgreen',
                weight: hoveredPolyline === 'NE3' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE3' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE3'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '13'].includes(item)) && (
            <Polyline
              positions={coordinatesNE4 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#512da8',
                weight: hoveredPolyline === 'NE4' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE4' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE4'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '13'].includes(item)) && (
            <Polyline
              positions={coordinatesNE4_1 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#512da8',
                weight: hoveredPolyline === 'NE4_1' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE4_1' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE4_1'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['1', '13'].includes(item)) && (
            <Polyline
              positions={coordinatesNE4_2 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#512da8',
                weight: hoveredPolyline === 'NE4_2' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NE4_2' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NE4_2'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['2', '20'].includes(item)) && (
            <Polyline
              positions={coordinatesSE as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#353935',
                weight: hoveredPolyline === 'SE' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SE' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SE'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['2', '21'].includes(item)) && (
            <Polyline
              positions={coordinatesSE2 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#b129ff',
                weight: hoveredPolyline === 'SE2' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SE2' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SE2'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['2', '21'].includes(item)) && (
            <Polyline
              positions={coordinatesSE2_EE as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#b129ff',
                weight: hoveredPolyline === 'SE2_EE' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SE2_EE' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SE2_EE'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['2', '22'].includes(item)) && (
            <Polyline
              positions={coordinatesSE3 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#ffc300',
                weight: hoveredPolyline === 'SE3' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SE3' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SE3'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['2', '22'].includes(item)) && (
            <Polyline
              positions={coordinatesSE3_EE as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#ffc300',
                weight: hoveredPolyline === 'SE3_EE' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'SE3_EE' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('SE3_EE'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['3', '30'].includes(item)) && (
            <Polyline
              positions={coordinatesNW as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#006064',
                weight: hoveredPolyline === 'NW' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NW' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NW'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['3', '31'].includes(item)) && (
            <Polyline
              positions={coordinatesNW2 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#581845',
                weight: hoveredPolyline === 'NW2' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NW2' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NW2'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
          {geofencing?.some((item) => ['3', '32'].includes(item)) && (
            <Polyline
              positions={coordinatesNW3 as unknown as L.LatLngExpression[]}
              pathOptions={{
                color: '#36454F',
                weight: hoveredPolyline === 'NW3' ? 5 : 2,
                fill: true,
                opacity: hoveredPolyline === 'NW3' ? 1 : 0.7
              }}
              eventHandlers={{
                mouseover: () => setHoveredPolyline('NW3'),
                mouseout: () => setHoveredPolyline(null)
              }}
            />
          )}
        </MapContainer>
      </div>
    </div>
  );
};

export default React.memo(OneMap);
