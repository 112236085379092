import React, { useEffect, useRef, useState } from 'react';
import FolderUploadDropzone from '../FolderUploadDropzone';
import { Breadcrumb, Button, Col, Container, Row, Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FileRejection, FileWithPath } from 'react-dropzone';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getFolder, getFolderFromBatchPavementMask, niceBytes } from '../../../../utils/helper';
import FilterComponent from '../FilterComponent';
import StatusColumn from '../StatusColumn';
import { FileWithPathAndError } from '../../uploadFolderType';
import { BsArrowRepeat, BsFillCloudUploadFill, BsFillTrashFill } from 'react-icons/bs';
import {
  cam1Pattern,
  cam2Pattern,
  cam3Pattern,
  cam4Pattern,
  cam5CorrPattern,
  cam5Pattern,
  equirecPattern,
  pano360Pattern,
  pavementMaskPattern,
  sensorPatternJson,
  sensorPatternTxt
} from '../../../../utils/const';
import { syncDataset, uploadBatchPavementFiles, uploadDataset } from '../../uploadFolderApi';
import { Popconfirm, Progress, message } from 'antd';
import pLimit from 'p-limit';
import axios from 'axios';
import authInstance from '../../../../Axios/authInstance';

// const MAX_BATCH_SIZE = 10;
const MAX_CONCURRENT_UPLOADS = 50;

const columns: TableColumn<FileWithPathAndError>[] = [
  {
    name: 'Name',
    selector: (row) => row.name,
    sortable: true,
    sortField: 'name',
    width: '30%'
  },
  {
    name: 'Folder',
    selector: (row) =>
      row.name == row.path
        ? row.name.includes('PavementMask')
          ? getFolderFromBatchPavementMask(row.path)
          : '-'
        : row.path
        ? getFolder(row.path)
        : '',
    sortable: true,
    sortField: 'path'
  },
  {
    name: 'Type',
    selector: (row) => row.type || '',
    sortable: true,
    sortField: 'type'
  },
  {
    name: 'Size',
    selector: (row) => niceBytes(row.size) || '',
    sortable: true,
    sortField: 'size'
  },
  {
    name: 'Status',
    cell: (row) => <StatusColumn rowData={row} />
  }
];

interface FileProgress {
  [key: string]: number;
}

const UploadDataset = () => {
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<FileWithPathAndError[]>([]);
  const [acceptedFiles, setAcceptedFile] = useState<FileWithPath[]>([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<FileWithPathAndError[]>([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [forceRenderTable, setForceRenderTable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [completedFiles, setCompletedFiles] = useState(0);
  // const [totalProgress, setTotalProgress] = useState(0);

  const [uploadProgress, setUploadProgress] = useState(0);
  const [syncProgress, setSyncProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [processComplete, setProcessComplete] = useState(false);

  const [totalFolder, setTotalFolder] = useState(0);
  const [folderSuccess, setFolderSuccess] = useState(0);
  const [folderFailed, setFolderFailed] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [dataFolder, setDataFolder] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const totalFiles = acceptedFiles.length;
  const [fileProgress, setFileProgress] = useState<FileProgress>({});

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return <FilterComponent onFilter={(e) => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  const renderTotalFile = (files: FileWithPath[]) => {
    if (files.length == 0) {
      return <span className="fs-5 text-secondary">0</span>;
    }
    let totalFileSize = 0;

    files.forEach((element) => {
      totalFileSize += element.size;
    });
    return (
      <span className="fs-5 text-secondary">
        ({files.length} Total, {niceBytes(totalFileSize)})
      </span>
    );
  };

  const filteredItems = files.filter(
    (item) =>
      (item.name && item.name.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.path && item.path.toLowerCase().includes(filterText.toLowerCase()))
  );

  const parseRejectedFileToFileType = (filesRejection: FileRejection[]): FileWithPathAndError[] => {
    return filesRejection.map((item: FileRejection) => {
      return {
        ...item.file,
        name: item.file.name,
        size: item.file.size,
        type: item.file.type,
        errors: item.errors,
        status: 'error'
      };
    });
  };

  const handleRowSelected = React.useCallback((state: { selectedRows: FileWithPathAndError[]; allSelected: boolean; selectedCount: number }) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const differenceBy = (files: FileWithPathAndError[], selectedRows: FileWithPathAndError[]): FileWithPathAndError[] => {
    const res = files.filter((x) => !selectedRows.some((y) => getFolder(y.path || '') === getFolder(x.path || '')));
    return res;
  };

  const handleDelete = () => {
    setToggleCleared(!toggleCleared);
    setFiles(differenceBy(files, selectedRows));
    setAcceptedFile([]);
    setSelectedRows([]);
  };

  function appendToFormData(formData: FormData, filesInFolder: FileWithPath[], folderName: string): FormData {
    formData.append('folders[]', folderName);
    for (let i = 0; i < filesInFolder.length; i++) {
      const file = filesInFolder[i];
      const fileName = file.name;

      if (cam1Pattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (cam2Pattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (cam3Pattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (cam4Pattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (cam5Pattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (cam5CorrPattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (pavementMaskPattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (sensorPatternTxt.test(fileName)) {
        if (formData.get(`${folderName}/${fileName}`) == null) {
          formData.append(`${folderName}/${fileName}`, file);
        }
      }
      if (sensorPatternJson.test(fileName)) {
        if (formData.get(`${folderName}/${fileName}`) !== null) {
          formData.delete(`${folderName}/${fileName}`);
        }
        formData.append(`${folderName}/${fileName}`, file);
      }
      if (pano360Pattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }

      if (equirecPattern.test(fileName)) {
        formData.append(`${folderName}/${fileName}`, file);
      }
    }
    return formData;
  }

  function groupArrayByFolder(arr: FileWithPath[]): { [key: string]: FileWithPath[] } {
    return arr.reduce((acc: { [key: string]: FileWithPath[] }, obj: FileWithPath) => {
      const folderName = extractFolderName(obj.path || '');
      if (!acc[folderName]) {
        acc[folderName] = [];
      }
      acc[folderName].push(obj);
      return acc;
    }, {});
  }

  function extractFolderName(path: string): string {
    const parts = path.split('/');
    return parts[parts.length - 2];
  }

  // Utility function to split an array into chunks
  const chunkArray = <T,>(array: T[], chunkSize: number): T[][] => {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSyncProgress = async () => {
    const folderNames = Object.keys(groupArrayByFolder(acceptedFiles));

    const BATCH_SIZE = 200;

    const batches = chunkArray(folderNames, BATCH_SIZE);
    const totalBatches = batches.length;
    let completedBatches = 0;

    setSyncProgress(0);
    setProcessComplete(true);

    try {
      for (let index = 0; index < batches.length; index++) {
        const batch = batches[index];
        await dispatch(syncDataset({ folderNames: batch })).unwrap();

        completedBatches += 1;
        const progress = Math.round((completedBatches / totalBatches) * 100);
        setUploadProgress(progress);
      }

      message.success('All datasets synced successfully!');
    } catch (error) {
      console.error('Sync datasets error:', error);
      message.error('An error occurred while syncing datasets.');
    } finally {
      setProcessComplete(false);
      setSyncProgress(100);
    }
  };

  const uploadFilesWithPresignedUrls = async () => {
    setUploadComplete(false);
    setUploadProgress(0);
    setSyncProgress(0);

    setForceRenderTable(true);
    setIsLoading(true);
    setIsUploaded(true);
    const totalFolder = Object.keys(groupArrayByFolder(acceptedFiles)).length;
    const BATCH_SIZE = 1000;

    setTotalFolder(totalFolder);

    setFiles((prevValue) => {
      const newVal = prevValue.map((item) => {
        if (item.status != 'error') {
          item.status = 'pending';
        }
        return item;
      });
      return newVal;
    });
    setForceRenderTable(true);

    const limit = pLimit(MAX_CONCURRENT_UPLOADS);
    const hide = message.loading('Uploading datasets...', 0);
    for (let i = 0; i < acceptedFiles.length; i += BATCH_SIZE) {
      const batch = acceptedFiles.slice(i, i + BATCH_SIZE);

      const filesForPresignedUrls = batch.map((file) => {
        const folderName = extractFolderName(file.path || '');
        return {
          key: `${folderName}/${file.name}`,
          contentType: file.type || 'application/octet-stream'
        };
      });

      const presignedUrlsResponse = await authInstance.post('/api/s3/generate-presigned-urls', {
        files: filesForPresignedUrls
      });

      const presignedUrls = presignedUrlsResponse.data as { key: string; url: string }[];

      const urlMap = presignedUrls.reduce((map, item) => {
        map[item.key] = item.url;
        return map;
      }, {} as Record<string, string>);

      const uploadPromises = batch.map((file) =>
        limit(async () => {
          const folderName = extractFolderName(file.path || '');
          const key = `${folderName}/${file.name}`;
          const url = urlMap[key];
          if (url) {
            try {
              await axios.put(url, file, {
                headers: {
                  'Content-Type': file.type || 'application/octet-stream'
                }
              });
              setCompletedFiles((prev) => {
                const newCompleted = prev + 1;
                setUploadProgress(Math.round((newCompleted / totalFiles) * 100));
                return newCompleted;
              });
            } catch (error) {
              console.error(`Error uploading ${file.name}:`, error);
            }
          } else {
            console.error(`No presigned URL found for ${key}`);
          }
        })
      );

      await Promise.all(uploadPromises);
    }

    try {
      message.success('All datasets upload successfully');
      setFiles((prevValue) => {
        const newVal = prevValue.map((item) => {
          if (item.status != 'error') {
            item.status = 'success';
          }
          return item;
        });
        return newVal;
      });
      setFolderSuccess(totalFolder);
    } catch (err) {
      console.error('Error uploading files:', err);
      message.error('File upload failed');
    } finally {
      hide();
      setForceRenderTable(true);
      setIsLoading(false);
    }
  };

  const handleOnConfirm = async () => {
    setForceRenderTable(true);
    setIsLoading(true);
    setIsUploaded(true);
    setFiles((prevValue) => {
      const newVal = prevValue.map((item) => {
        if (item.status != 'error') {
          item.status = 'pending';
        }
        return item;
      });
      return newVal;
    });
    setForceRenderTable(true);
    const formData = new FormData();
    for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append(`pavementFile${i}`, acceptedFiles[i]);
    }
    formData.append('overwrite', 'true');
    dispatch(uploadBatchPavementFiles(formData)).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        setFiles((prevValue) => {
          const newVal = prevValue.map((item) => {
            if (item.status != 'error') {
              item.status = 'error';
              item.errors = [{ message: res.payload as string, code: res.type }];
            }
            return item;
          });
          return newVal;
        });
      } else {
        setFiles((prevValue) => {
          const newVal = prevValue.map((item) => {
            if (item.status != 'error') {
              item.status = 'success';
            }
            return item;
          });
          return newVal;
        });
        messageApi.open({
          type: 'success',
          content: 'Upload and overwrite PavementMask files successfully!'
        });
      }
      setForceRenderTable(true);
      setIsLoading(false);
    });
  };

  const handleOnCancel = () => {
    setForceRenderTable(true);
    setIsLoading(true);
    setIsUploaded(true);
    setFiles((prevValue) => {
      const newVal = prevValue.map((item) => {
        if (item.status != 'error') {
          item.status = 'pending';
        }
        return item;
      });
      return newVal;
    });
    setForceRenderTable(true);
    const formData = new FormData();
    for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append(`pavementFile${i}`, acceptedFiles[i]);
    }
    formData.append('overwrite', 'false');
    dispatch(uploadBatchPavementFiles(formData)).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        setFiles((prevValue) => {
          const newVal = prevValue.map((item) => {
            if (item.status != 'error') {
              item.status = 'error';
              item.errors = [{ message: res.payload as string, code: res.type }];
            }
            return item;
          });
          return newVal;
        });
      } else {
        setFiles((prevValue) => {
          const newVal = prevValue.map((item) => {
            if (item.status != 'error') {
              item.status = 'success';
            }
            return item;
          });
          return newVal;
        });
        messageApi.open({
          type: 'success',
          content: 'Upload PavementMask files successfully!'
        });
      }
      setForceRenderTable(true);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (forceRenderTable) {
      setForceRenderTable(false);
    }
  }, [forceRenderTable]);

  const handleRemoveAll = () => {
    setFiles([]);
    setAcceptedFile([]);
    setIsUploaded(false);
  };

  const columnsFolder: TableColumn<any>[] = [
    {
      name: 'Folder Name',
      selector: (row) => row.name,
      sortable: true,
      sortField: 'name'
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      sortField: 'status'
    }
  ];

  const handleClose = () => {
    setShowDetails(false);
  };

  return (
    <>
      {contextHolder}
      <Container>
        <Modal show={showDetails} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Detail Error</Modal.Title>
          </Modal.Header>
          {showDetails ? (
            <Modal.Body>
              <DataTable
                noHeader={true}
                highlightOnHover={true}
                pointerOnHover={true}
                striped={true}
                pagination={true}
                columns={columnsFolder}
                data={dataFolder || []}
              />
            </Modal.Body>
          ) : (
            ''
          )}
        </Modal>
        <Row className="mt-3">
          <Col>
            <Col lg="12">
              <Breadcrumb>
                <Breadcrumb.Item linkAs="div">
                  <NavLink to={`/detected-locations/`} id="RouterNavLink">
                    Detected location
                  </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>Upload Datasets</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Col>
          <Col>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ color: '#6c757d' }}>Upload progress</span>
                <Progress percent={uploadProgress} status="active" style={{ width: '75%' }} />
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* <Button
                          variant="primary"
                          className="me-1"
                          disabled={isLoading || acceptedFiles.length <= 0 || isUploaded}
                          onClick={() => {
                            uploadFilesWithPresignedUrls();
                          }}>
                          {isLoading ? (
                            <>
                              <div className="spinner-border spinner-border-sm me-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              loading...
                            </>
                          ) : (
                            <>
                              <BsFillCloudUploadFill className="me-1" /> Upload
                            </>
                          )}
                        </Button> */}
                <Button
                  disabled={processComplete}
                  style={{ color: '#6c757d' }}
                  onClick={() => {
                    handleSyncProgress();
                  }}>
                  Sync progress
                </Button>
                <Progress percent={syncProgress} status="active" style={{ width: '75%' }} />
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row className="mb-3">
          <FolderUploadDropzone
            filesUploaded={files}
            handleFiles={(files: FileWithPath[]) => {
              setFiles((prevNum) => {
                return [...prevNum, ...files];
              });
              setAcceptedFile((prevNum) => {
                return [...prevNum, ...files];
              });
            }}
            handleRejectedFiles={(files: FileRejection[]) => {
              setFiles((prevNum) => {
                prevNum.concat();
                return [...prevNum, ...parseRejectedFileToFileType(files)];
              });
            }}></FolderUploadDropzone>
        </Row>
        <Row className="mb-3">
          <div>Total folder uploaded: {totalFolder ? totalFolder : 0}</div>
          <div>Total folder uploaded successfully: {folderSuccess}</div>
          <div>Total folder uploaded failed: {folderFailed}</div>
        </Row>

        <Row>
          <Col>
            <Container className="shadow p-3 mb-5 bg-body rounded">
              <Row>
                <Col sm="12" className="mb-3">
                  <Row>
                    <Col>
                      <div className="fs-4">Folders upload {renderTotalFile(files)}</div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      {files && files.length && files[0].name !== files[0].path ? (
                        <Button
                          variant="primary"
                          className="me-1"
                          disabled={isLoading || acceptedFiles.length <= 0 || isUploaded}
                          onClick={() => {
                            uploadFilesWithPresignedUrls();
                          }}>
                          {isLoading ? (
                            <>
                              <div className="spinner-border spinner-border-sm me-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              loading...
                            </>
                          ) : (
                            <>
                              <BsFillCloudUploadFill className="me-1" /> Upload
                            </>
                          )}
                        </Button>
                      ) : (
                        <Popconfirm
                          title="Upload batch PavementMask File"
                          description="Do you want overwrite the existing PavementMask.txt files?"
                          okText="Yes"
                          cancelText="No"
                          onCancel={() => handleOnCancel()}
                          onConfirm={() => handleOnConfirm()}>
                          <Button variant="primary" className="me-1" disabled={isLoading || acceptedFiles.length <= 0 || isUploaded}>
                            {isLoading ? (
                              <>
                                <div className="spinner-border spinner-border-sm me-1" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                                loading...
                              </>
                            ) : (
                              <>
                                <BsFillCloudUploadFill className="me-1" /> Upload
                              </>
                            )}
                          </Button>
                        </Popconfirm>
                      )}

                      <Button variant="secondary" className="me-1" onClick={handleRemoveAll} disabled={isLoading || files.length <= 0}>
                        <BsArrowRepeat className="me-1"></BsArrowRepeat>
                        Reset
                      </Button>
                      <Button variant="secondary" className="me-1" onClick={handleDelete} disabled={selectedRows.length <= 0 || isLoading}>
                        <BsFillTrashFill className="me-1"></BsFillTrashFill>
                        Remove Datasets
                      </Button>
                      {folderFailed ? (
                        <Button variant="secondary" className="me-1" onClick={() => setShowDetails(true)}>
                          Detail Errors
                        </Button>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <div className="table-responsive">
                    {subHeaderComponentMemo}
                    <DataTable
                      noHeader={true}
                      highlightOnHover={true}
                      pointerOnHover={true}
                      striped={true}
                      pagination={true}
                      columns={columns}
                      data={filteredItems || []}
                      paginationResetDefaultPage={resetPaginationToggle}
                      selectableRows
                      persistTableHead
                      onSelectedRowsChange={handleRowSelected}
                      clearSelectedRows={toggleCleared}
                      progressPending={forceRenderTable}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default UploadDataset;
