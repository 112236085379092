import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  deleteDetectedLocationDetail,
  downloadReportPdf,
  getDetectedLocation,
  getDetectedLocationDetail,
  getDrawRectangleImageById,
  getLatLng,
  getPanoImageByDetectedLocationId,
  updateDetectedData,
  getAllRoadName,
  getAllGlacier,
  getExportPDF,
  generatePanoImageByFolderName,
  getNumberDefectIds
} from './detectedLocationApi';
import { DetectedLocationSearchValue, DetectedLocationState } from './detectedLocationType';
/* eslint-disable */

const initialState: DetectedLocationState = {
  data: null,
  error: null,
  success: false,
  page: 1,
  perPage: 10,
  loading: false,
  isShowMapModal: false,
  detectedLocationSearchValue: {},
  roadNames: [],
  totalDistance: 0,
  route: null,
  years: null
};

export const selectLoading = (state: RootState) => state.detectedLocation.loading;
export const selectDrawImageLoading = (state: RootState) => state.detectedLocation.drawImageLoading;
export const selectDownloadReportLoading = (state: RootState) => state.detectedLocation.downloadReportLoading;
export const selectDetectedLocation = (state: RootState) => state.detectedLocation;
export const selectExportPdfModal = (state: RootState) => state.detectedLocation.isShowExportPdfModal;
export const selectDetectedLocationDetail = (state: RootState) => state.detectedLocation.detectedLocationDetail;
export const selectDetectedLocationList = (state: RootState) => state.detectedLocation.data;
export const selectDetectedLocationSearch = (state: RootState) => state.detectedLocation.detectedLocationSearchValue;
export const selectDrawRectangleImage = (state: RootState) => state.detectedLocation.drawRectangleImage;
export const selectDrawRectangleImageNone = (state: RootState) => state.detectedLocation.drawRectangleImageNone;
export const selectHeightImage = (state: RootState) => state.detectedLocation.height;
export const selectWidthImage = (state: RootState) => state.detectedLocation.width;
export const selectDefaultDrawRectangleImageNone = (state: RootState) => state.detectedLocation.defaultDrawRectangleImage;
export const selectPanoImage = (state: RootState) => state.detectedLocation.panoImage;
export const selectPanoLoading = (state: RootState) => state.detectedLocation.getPanoLoading;
export const selectPage = (state: RootState) => state.detectedLocation.page;
export const selectPerPage = (state: RootState) => state.detectedLocation.perPage;
export const selectLatLng = (state: RootState) => state.detectedLocation.latLngList;
export const selectRoadNames = (state: RootState) => state.detectedLocation.roadNames;
export const selectTotalDistance = (state: RootState) => state.detectedLocation.totalDistance;
export const selectAllDataDetectedLocation = (state: RootState) => state.detectedLocation.allData;
export const selectRoute = (state: RootState) => state.detectedLocation.route;
export const selectYearGlacier = (state: RootState) => state.detectedLocation.years;
export const selectUrl = (state: RootState) => state.detectedLocation.url;
export const selectNumberDefectIds = (state: RootState) => state.detectedLocation.dataCrack;

const DetectedLocationSlice = createSlice({
  name: 'detectedLocation',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDetectedLocation.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDetectedLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.trend = action.payload.trend;
      state.totalDistance = action.payload.totalDistance;
      state.allData = action.payload.allData;
      state.route = action.payload.route;
      // state.detectedLocationDetail = action.payload.data.findIndex(item => item.id == state.detectedLocationDetail?.id) >=0
      //   ? state.detectedLocationDetail
      //   : action.payload.data[0];
      state.perPage = action.meta.arg.perPage ? action.meta.arg.perPage : state.perPage;
      state.page = action.meta.arg.page ? action.meta.arg.page : state.page;
      state.detectedLocationSearchValue = {
        ...state.detectedLocationSearchValue,
        ...action.meta.arg
      };
    });
    builder.addCase(getDetectedLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getDetectedLocationDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDetectedLocationDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.detectedLocationDetail = action.payload;
    });
    builder.addCase(getDetectedLocationDetail.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getDrawRectangleImageById.pending, (state) => {
      state.drawImageLoading = true;
      state.error = null;
    });
    builder.addCase(getDrawRectangleImageById.fulfilled, (state, action) => {
      state.drawImageLoading = false;
      state.drawRectangleImage = action.payload.image;
      state.drawRectangleImageNone = action.payload.imageNone;
      state.defaultDrawRectangleImage = action.payload.defaultDrawRectangleImage;
      state.height = action.payload.height;
      state.width = action.payload.width;
    });
    builder.addCase(getDrawRectangleImageById.rejected, (state, action) => {
      state.drawImageLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getPanoImageByDetectedLocationId.pending, (state) => {
      state.getPanoLoading = true;
      state.error = null;
    });
    builder.addCase(getPanoImageByDetectedLocationId.fulfilled, (state, action) => {
      state.getPanoLoading = false;
      state.panoImage = action.payload.image;
    });
    builder.addCase(getPanoImageByDetectedLocationId.rejected, (state, action) => {
      state.getPanoLoading = false;
      state.error = action.payload;
    });
    builder.addCase(generatePanoImageByFolderName.pending, (state) => {
      state.getPanoLoading = true;
      state.error = null;
    });
    builder.addCase(generatePanoImageByFolderName.fulfilled, (state, action) => {
      state.getPanoLoading = false;
      state.panoImage = action.payload.image;
    });
    builder.addCase(generatePanoImageByFolderName.rejected, (state, action) => {
      state.getPanoLoading = false;
      state.error = action.payload;
    });
    builder.addCase(downloadReportPdf.pending, (state, action) => {
      state.downloadReportLoading = true;
      state.error = null;
      state.downloadReportId = action.meta.arg.detectedLocationId;
    });
    builder.addCase(downloadReportPdf.fulfilled, (state) => {
      state.downloadReportLoading = false;
    });
    builder.addCase(downloadReportPdf.rejected, (state, action) => {
      state.downloadReportLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updateDetectedData.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateDetectedData.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateDetectedData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteDetectedLocationDetail.fulfilled, (state, action) => {
      if (!state.data) return;
      state.data = [...state.data.filter((item) => item.id !== action.meta.arg.id)];
      state.loading = false;
    });
    builder.addCase(getLatLng.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLatLng.fulfilled, (state, action) => {
      state.loading = false;
      state.latLngList = action.payload;
    });
    builder.addCase(getLatLng.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllRoadName.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllRoadName.fulfilled, (state, action) => {
      state.loading = false;
      state.roadNames = action.payload;
    });
    builder.addCase(getAllRoadName.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getAllGlacier.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllGlacier.fulfilled, (state, action) => {
      state.loading = false;
      state.years = action.payload;
    });
    builder.addCase(getAllGlacier.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getExportPDF.pending, (state) => {
      state.error = null;
    });
    builder.addCase(getExportPDF.fulfilled, (state, action) => {
      state.url = action.payload;
    });
    builder.addCase(getExportPDF.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
  reducers: {
    toggleShowExportPdfModal: (state, action) => {
      state.isShowExportPdfModal = action.payload;
    },
    setDetectedLocationDetail: (state, action) => {
      state.detectedLocationDetail = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<DetectedLocationSearchValue>) => {
      state.detectedLocationSearchValue = {
        ...state.detectedLocationSearchValue,
        ...action.payload
      };
    },
    resetDetectedLocationDetail: (state) => {
      state.detectedLocationDetail = undefined;
      state.drawRectangleImage = undefined;
      state.getPanoLoading = false;
      state.panoImage = undefined;
    },
    setDataCrack: (state, action) => {
      state.dataCrack = action.payload;
    }
  }
});
export const { toggleShowExportPdfModal, setDetectedLocationDetail, resetDetectedLocationDetail, setSearchValue, setDataCrack } =
  DetectedLocationSlice.actions;

export default DetectedLocationSlice.reducer;
