import { useEffect, useRef, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getAllUsers } from '../../authApi';
import { DateTime } from 'luxon';
import ActionApproveColumn from './ActionApproveColumn';
import ActionRoleColumn from './ActionRoleColumn';
import ReactLoading from 'react-loading';
import { cancelReportPDFs, getAllReportPDFs, getReportPdfUrl, updateProgressPDFs } from '../../../uploadFolder/uploadFolderApi';
import { selectUserProfile } from '../../authSlice';
import { FaDownload } from 'react-icons/fa';
import { message, Progress } from 'antd';
import io from 'socket.io-client';

const ReportPDFs = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUserProfile);
  const [messageApi, contextHolder] = message.useMessage();
  const [disabledButtons, setDisabledButtons] = useState<Set<string>>(new Set());
  const socket = useRef<any>(null);
  const [progressData, setProgressData] = useState<any>({});
  const [waitingJob, setWaitingJob] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    profile?.id &&
      dispatch(getAllReportPDFs({ userId: profile.id })).then((res: any) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setData(res.payload);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, [dispatch]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_URL || '', {
      path: '/lighthaus/backend/socket.io',
      transports: ['websocket', 'polling'],
      rejectUnauthorized: false
    });

    socket.current.on('connect', () => {
      console.log('socket connected', socket.current.id);
      dispatch(updateProgressPDFs({ socketId: socket.current.id, userId: profile?.id || '' }));
    });

    socket.current.on('pdf_progress', (data: any) => {
      if (data === null) return;
      setProgressData(data.reportPdfs);
      setWaitingJob(data.waitingJobs);
    });

    console.log(progressData);

    return () => socket.current.disconnect();
  }, []);

  const CustomLoading = () => {
    return loading ? <ReactLoading type={'spin'} color="#000" /> : null;
  };

  const columns: TableColumn<any>[] = [
    {
      name: 'No.',
      cell: (row, rowIndex) => <>{rowIndex + 1}</>,
      sortable: false,
      width: '70px'
    },

    {
      name: 'Created At',
      selector: (row) => DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm'),
      sortable: true,
      sortField: 'createdAt',
      width: '180px'
    },
    {
      name: 'URL',
      width: '200px',
      cell: (row) =>
        row.status === 2 ? (
          'Cancelled'
        ) : row.status === 0 && waitingJob.length && row.jobId.includes(waitingJob) ? (
          `Job queued ${waitingJob.findIndex((job) => job.jobId === row.jobId) + 1} / ${waitingJob.length}`
        ) : row.status === 0 ? (
          'Generating...'
        ) : (
          <a target="_blank" rel="noreferrer">
            <FaDownload color="#FF7200" style={{ marginRight: '5px' }} />
            Download
          </a>
        )
    },
    {
      name: 'Action',
      width: '200px',
      cell: (row) =>
        row.status === 0 ? (
          <Button variant="outline-primary" onClick={() => handleCancelPdf(row)} disabled={disabledButtons.has(row.id)}>
            {disabledButtons.has(row.id) ? 'Cancelling...' : 'Cancel'}
          </Button>
        ) : (
          ''
        )
    },
    {
      name: 'Progress',
      width: '200px',
      cell: (row) => (
        <Progress
          percent={
            row.total == null
              ? 100
              : Math.ceil(((progressData && progressData.id === row.id ? progressData.processed : row.processed) / row.total) * 100)
          }
          size="small"
        />
      )
    },
    {
      name: '',
      cell: (row) => (
        <span>
          ({row.processed < row.total ? (progressData && progressData.id === row.id ? progressData.processed : row.processed) : row.total}/{row.total}
          )
        </span>
      )
    }
  ];

  const handleRowClicked = (row: any) => {
    dispatch(getReportPdfUrl({ jobId: row.jobId })).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        window.open(res.payload, '_blank');
      }
    });
  };

  const handleCancelPdf = (row: any) => {
    setDisabledButtons((prev) => new Set(prev).add(row.id));

    dispatch(cancelReportPDFs({ jobId: row.jobId })).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        message.success('Cancel successfully!');
      } else {
        message.error('Cancel failed!');
      }
      setDisabledButtons((prev) => {
        const newSet = new Set(prev);
        newSet.delete(row.id);
        return newSet;
      });
    });
  };

  return (
    <>
      {contextHolder}
      <Container>
        <div className="table-responsive">
          <DataTable
            noHeader={true}
            highlightOnHover={true}
            pointerOnHover={true}
            striped={true}
            pagination={true}
            progressPending={loading}
            progressComponent={<CustomLoading></CustomLoading>}
            columns={columns}
            data={data || []}
            onRowClicked={handleRowClicked}
          />
        </div>
      </Container>
    </>
  );
};

export default ReportPDFs;
