import { DateTime } from 'luxon';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getExportPDF } from '../detectedLocationApi';
import {
  selectDetectedLocation,
  selectDetectedLocationDetail,
  selectDetectedLocationSearch,
  setDetectedLocationDetail
} from '../detectedLocationSlice';
import { DetectedLocationData } from '../detectedLocationType';
import ActionColumn from './ActionColumn';
import { useSearchParams } from 'react-router-dom';
import { FiFile } from 'react-icons/fi';
import StatusColumn from './StatusColumn';
import { Button } from 'react-bootstrap';
import differenceBy from 'lodash/differenceBy';
import { setSelectedIds, setAllSelected } from '../../uploadFolder/uploadFolderSlice';
import { LuFileInput } from 'react-icons/lu';
import ReactLoading from 'react-loading';
import { Spin } from 'antd';

type Props = {
  fetchData: (perPage?: number, page?: number, orderBy?: string, order?: string) => void;
};

export const ClickableIcon: React.FC<any> = ({ defaultKey }) => {
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState<string>('');
  // useEffect(() => {
  //   getURL();
  // }, []);
  // const getURL = () => {
  //   dispatch(getExportPDF({ key: defaultKey })).then((res: any) => {
  //     if (res.meta.requestStatus === 'fulfilled') {
  //       setUrl(res.payload);
  //     }
  //   });
  // };
  // return url ? (
  //   <a href={url} target="_blank" rel="noopener noreferrer">
  //     <LuFileInput size={18} />
  //   </a>
  // ) : null;

  return (
    <a target="_blank" rel="noopener noreferrer">
      <LuFileInput
        size={18}
        color="#FF7200"
        onClick={() => {
          dispatch(getExportPDF({ key: defaultKey })).then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              window.open(res.payload, '_blank');
            }
          });
        }}
      />
    </a>
  );
};

const DefaultIcon: React.FC<any> = () => {
  return (
    <a target="_blank" rel="noopener noreferrer">
      <LuFileInput color="green" size={18} />
    </a>
  );
};

const DetectedLocationTable: React.FC<Props> = ({ fetchData }) => {
  const dispatch = useAppDispatch();
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const { data, loading, total, page, perPage } = useAppSelector(selectDetectedLocation);
  const [sortedData, setSortedData] = useState<DetectedLocationData[]>([]);
  // const [localSelectedRows, setLocalSelectedRows] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [order, setOrder] = useState<TableColumn<DetectedLocationData>>();
  const [orderBy, setOrderBy] = useState<string>();

  const columns: TableColumn<DetectedLocationData>[] = [
    {
      name: '',
      width: '50px',
      cell: (row) => <ActionColumn rowData={row} />
    },
    {
      // id: 1,
      name: 'No.',
      cell: (row, rowIndex) => <>{page && perPage ? rowIndex + 1 + perPage * (page - 1) : rowIndex}</>,
      sortable: false,
      width: '60px'
    },
    {
      // id: 2,
      name: 'Name',
      selector: (row) => row.folderName,
      sortable: true,
      sortField: 'folderName',
      width: '190px'
    },
    {
      // id: 1,
      name: '',
      cell: (row) => (row.statusFile == 1 ? <DefaultIcon /> : row.exportPDF ? <ClickableIcon defaultKey={row.id} /> : null),
      sortable: false,
      width: '1px'
    },
    {
      // id: 1,
      name: 'Status',
      cell: (row) => <StatusColumn rowData={row} />,
      sortable: false,
      width: '170px'
    },
    {
      // id: 3,
      name: 'Datetime',
      selector: (row) => DateTime.fromFormat(`${row.date}${row.time}`, 'yyyyMMddHHmmssSSS').toFormat('dd/MM/yyyy HH:mm:ss'),
      sortable: true,
      sortField: 'datetime',
      width: '160px'
    },
    {
      // id: 6,
      name: 'Detected',
      selector: (row) => (row.isCrackDetected ? 'Yes' : 'No'),
      sortable: true,
      sortField: 'isCrackDetected'
    },
    {
      // id: 7,
      name: 'Trigger type',
      selector: (row) => row.triggerType.charAt(0).toUpperCase() + row.triggerType.slice(1).toLocaleLowerCase(),
      sortable: true,
      sortField: 'folderName',
      width: '150px'
    },
    {
      // id: 8,
      name: 'Road name',
      selector: (row: any) => row.roadName,
      sortable: true,
      sortField: 'roadName',
      width: '200px'
    },
    {
      // id: 4,
      name: 'Latitude',
      selector: (row) => row.latitude,
      sortable: true,
      sortField: 'latitude'
    },
    {
      // id: 5,
      name: 'Longtitude',
      selector: (row) => row.longitude,
      sortable: true,
      sortField: 'longitude',
      width: '150px'
    }
  ];

  const searchValue = useAppSelector(selectDetectedLocationSearch);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleRowClicked = (row: DetectedLocationData) => {
    dispatch(setDetectedLocationDetail(row));
  };

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.length}?`)) {
      setToggleCleared(!toggleCleared);
      setSortedData(differenceBy(data, selectedRows, 'id'));
    }
  };

  const handleRowSelected = useCallback((state: any) => {
    dispatch(setDetectedLocationDetail(state.selectedRows[0]));
    dispatch(setSelectedIds(state.selectedRows));
    dispatch(setAllSelected(state.selectedCount === total));
    setSelectedRows(state.selectedRows);
  }, []);

  const handlePageChange = (page: number) => {
    if (searchParams.get('page')?.toString() != page.toString()) {
      fetchData(perPage, page, order?.sortField, orderBy);
    }
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    fetchData(newPerPage, page, order?.sortField, orderBy);
  };

  const handleSort = async (column: TableColumn<DetectedLocationData>, sortDirection: SortOrder) => {
    setOrder(column);
    setOrderBy(sortDirection);
    fetchData(perPage, page, column.sortField, sortDirection);
  };

  const contextActions = useMemo(() => {
    return <Button onClick={handleDelete}>Delete</Button>;
  }, [sortedData, selectedRows, toggleCleared]);

  return (
    <div className="table-responsive">
      <DataTable
        noHeader={true}
        pagination={true}
        columns={columns}
        data={data || []}
        paginationTotalRows={total}
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        defaultSortFieldId={orderBy}
        defaultSortAsc={false}
        highlightOnHover={true}
        pointerOnHover={true}
        striped={true}
        progressPending={loading}
        progressComponent={<Spin spinning={loading} />}
        onChangePage={handlePageChange}
        paginationServer
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        sortServer
        onRowClicked={handleRowClicked}
        paginationDefaultPage={page}
        paginationPerPage={perPage}
        // selectableRowsNoSelectAll
        selectableRowsVisibleOnly
        customStyles={{
          rows: {
            style: {
              marginBottom:
                total && perPage && page && total % perPage === 1 && Math.floor(total / perPage) + 1 === page
                  ? '70px'
                  : total && perPage && page && total % perPage === 2 && Math.floor(total / perPage) + 1 === page
                  ? '20px'
                  : 0
            }
          }
        }}
        conditionalRowStyles={[
          {
            when: (row) => row.folderName == searchParams.get('selectFolder'),
            style: {
              backgroundColor: 'rgb(255 114 0 / 54%)'
            }
          }
        ]}
      />
    </div>
  );
};

export default React.memo(DetectedLocationTable);
