import React, { useState } from 'react';
import { TreeSelect } from 'antd';

const { SHOW_PARENT } = TreeSelect;

const treeData = [
  {
    title: 'South West (SW)',
    value: '0',
    key: '0',
    children: [
      {
        title: 'SW1',
        value: '00',
        key: '00'
      },
      {
        title: 'SW2',
        value: '01',
        key: '01'
      },
      {
        title: 'SW3',
        value: '02',
        key: '02'
      }
    ]
  },
  {
    title: 'North East (NE)',
    value: '1',
    key: '1',
    children: [
      {
        title: 'NE1',
        value: '10',
        key: '10'
      },

      {
        title: 'NE2',
        value: '11',
        key: '11'
      },

      {
        title: 'NE3',
        value: '12',
        key: '12'
      },
      {
        title: 'NE4',
        value: '13',
        key: '13'
      }
    ]
  },
  {
    title: 'South East (SE)',
    value: '2',
    key: '2',
    children: [
      {
        title: 'SE1',
        value: '20',
        key: '20'
      },

      {
        title: 'SE2',
        value: '21',
        key: '21'
      },
      {
        title: 'SE3',
        value: '22',
        key: '22'
      }
    ]
  },
  {
    title: 'North West (NW)',
    value: '3',
    key: '3',
    children: [
      {
        title: 'NW1',
        value: '30',
        key: '30'
      },
      {
        title: 'NW2',
        value: '31',
        key: '31'
      },
      {
        title: 'NW3/CW',
        value: '32',
        key: '32'
      }
    ]
  }
];

const SearchGeofencing = ({ onChangeGeofencing }: { onChangeGeofencing: any }) => {
  const [value, setValue] = useState<string[]>();

  const onChange = (newValue: string[]) => {
    onChangeGeofencing(newValue);
  };

  const tProps = {
    treeData,
    value,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Please select',
    style: {
      width: '100%'
    },
    treeDefaultExpandAll: true
  };

  return <TreeSelect {...tProps} size="large" />;
};

export default SearchGeofencing;
