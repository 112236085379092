import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import DetectedLocation from '../../features/detectedLocation/components/core/DetectedLocation';
import { getDetectedLocation, getAllRoadName } from '../../features/detectedLocation/detectedLocationApi';
import { DateTime } from 'luxon';
import { useSearchParams } from 'react-router-dom';
import {
  selectDetectedLocationDetail,
  setDetectedLocationDetail,
  setSearchValue,
  selectDetectedLocationList
} from '../../features/detectedLocation/detectedLocationSlice';
import { setSelectedLocation, setSelectedLocationB } from '../../features/onemap/onemapSlice';
import { v4 as uuidv4 } from 'uuid';
import io from 'socket.io-client';
import { Spin } from 'antd';

const DetectedLocationPage = () => {
  const dispatch = useAppDispatch();
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const data = useAppSelector(selectDetectedLocationList);
  const [searchParams, setSearchParams] = useSearchParams();
  const [totalDistance, setTotalDistance] = useState<number>(0);
  const [dataLocationOneMap, setDataLocationOneMap] = useState<any>([]);
  const [currentSearchId, setCurrentSearchId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const socket = useRef<any>(null);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_URL || '', {
      path: '/lighthaus/backend/socket.io',
      transports: ['websocket', 'polling'],
      rejectUnauthorized: false
    });

    const newSearchId = uuidv4();

    socket.current.on('connect', () => {
      setCurrentSearchId(newSearchId);
      dispatch(
        getDetectedLocation({
          fromDate: searchParams.get('fromDate') ? searchParams.get('fromDate') || '' : DateTime.now().toFormat('yyyyMMdd'),
          toDate: searchParams.get('toDate') ? searchParams.get('toDate') || '' : DateTime.now().toFormat('yyyyMMdd'),
          isCrackDetected: searchParams.get('isDetected') == null ? true : searchParams.get('isDetected') == 'true',
          page: searchParams.get('page') ? parseInt(searchParams.get('page') || '1') : undefined,
          perPage: searchParams.get('perPage') ? parseInt(searchParams.get('perPage') || '10') : undefined,
          order: searchParams.get('order') ? searchParams.get('order') || '' : undefined,
          orderBy: searchParams.get('orderBy') ? searchParams.get('orderBy') || '' : undefined,
          fromTime: searchParams.get('fromTime') ? searchParams.get('fromTime') || '' : undefined,
          toTime: searchParams.get('toTime') ? searchParams.get('toTime') || '' : undefined,
          triggerType: searchParams.get('triggerType') ? searchParams.get('triggerType') || '' : undefined,
          latitude: searchParams.get('latitude') ? parseFloat(searchParams.get('latitude') || '') : undefined,
          longitude: searchParams.get('longitude') ? parseFloat(searchParams.get('longitude') || '') : undefined,
          latitudeB: searchParams.get('latitudeB') ? parseFloat(searchParams.get('latitudeB') || '') : undefined,
          longitudeB: searchParams.get('longitudeB') ? parseFloat(searchParams.get('longitudeB') || '') : undefined,
          radius: searchParams.get('radius') ? parseFloat(searchParams.get('radius') || '') : undefined,
          defectTypes: searchParams.get('defectTypes') ? searchParams.get('defectTypes') || '' : undefined,
          roadName: searchParams.get('roadName') ? searchParams.get('roadName') || '' : undefined,
          status: searchParams.get('status') ? parseInt(searchParams.get('status') || '') : undefined,
          filterBy: searchParams.get('filterBy') ? searchParams.get('filterBy') || 'month' : undefined,
          distance: 60,
          socketId: socket.current.id,
          searchId: newSearchId
        })
      );
    });

    socket.current.on('onemap_location', (payload: any) => {
      const { data: batchData, searchId } = payload;
      if (searchId === newSearchId) {
        setDataLocationOneMap((prevData: any) => [...prevData, ...batchData]);
        setTotalDistance((prevDistance) => prevDistance + payload.totalDistance);
      }
    });

    socket.current.on('onemap_location_complete', (payload: any) => {
      const { searchId } = payload;
      if (searchId === newSearchId) {
        setLoading(false);
      }
    });

    return () => {
      socket.current.off('connect');
      socket.current.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    if (!searchParams.get('selectFolder') && data?.length) {
      dispatch(setSearchValue({ selectFolder: searchParams.get('selectFolder') || undefined }));
    } else if (searchParams.get('selectFolder') && data?.length) {
      const selectData = data?.find((item) => item.folderName == searchParams.get('selectFolder'));
      if (selectData) dispatch(setDetectedLocationDetail(selectData));
    }
  }, [data]);

  useEffect(() => {
    dispatch(setSearchValue({ selectFolder: searchParams.get('selectFolder') || undefined }));
  }, [searchParams.get('selectFolder')]);

  useEffect(() => {
    if (searchParams.get('latitude') && searchParams.get('latitude') != 'undefined') {
      dispatch(
        setSelectedLocation({
          LATITUDE: parseFloat(searchParams.get('latitude') || ''),
          LONGITUDE: parseFloat(searchParams.get('longitude') || ''),
          LONGTITUDE: parseFloat(searchParams.get('longitude') || '')
        })
      );
    }
  }, [searchParams.get('latitude')]);

  useEffect(() => {
    if (searchParams.get('latitudeB') && searchParams.get('latitudeB') != 'undefined') {
      dispatch(
        setSelectedLocationB({
          LATITUDE: parseFloat(searchParams.get('latitudeB') || ''),
          LONGITUDE: parseFloat(searchParams.get('longitudeB') || ''),
          LONGTITUDE: parseFloat(searchParams.get('longitudeB') || '')
        })
      );
    }
  }, [searchParams.get('latitudeB')]);

  useEffect(() => {
    if (detectedLocationDetail?.folderName) {
      searchParams.set('selectFolder', detectedLocationDetail.folderName);
      setSearchParams(searchParams);
    }
  }, [detectedLocationDetail]);

  useEffect(() => {
    dispatch(getAllRoadName());
  }, [dispatch]);

  const props = {
    fromD: searchParams.get('fromDate'),
    toD: searchParams.get('toDate'),
    roadName: searchParams.get('roadName'),
    defectTypes: searchParams.get('defectTypes'),
    isCrackDetected: searchParams.get('isCrackDetected'),
    page: searchParams.get('page'),
    perPage: searchParams.get('perPage'),
    order: searchParams.get('order'),
    orderBy: searchParams.get('orderBy'),
    fromTime: searchParams.get('fromTime'),
    toTime: searchParams.get('toTime'),
    triggerType: searchParams.get('triggerType'),
    latitude: searchParams.get('latitude'),
    longitude: searchParams.get('longitude'),
    latitudeB: searchParams.get('latitudeB'),
    longitudeB: searchParams.get('longitudeB'),
    radius: searchParams.get('radius'),
    status: searchParams.get('status'),
    isDetected: searchParams.get('isDetected') == null ? true : searchParams.get('isDetected')?.toString() == 'true',
    dataLocationOneMap: dataLocationOneMap,
    totalDistance: totalDistance
  };

  if (loading) {
    return <Spin />;
  }
  return <DetectedLocation props={props}></DetectedLocation>;
};

export default DetectedLocationPage;
