import { useState } from 'react';
import { Breadcrumb, Col, Container, Row, Button, Tabs, Tab } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectUserProfile, toggleShowModal, selectTelegramInfo } from '../../../authSlice';
import SavedDetectedLocation from '../../Table/SavedDetectedLocation';
import UpdateTelegramForm from '../../Form/UpdateTelegramForm';
import UpdateProfileForm from '../../Form/UpdateProfileForm';
import { BsFillPencilFill } from 'react-icons/bs';
import ChangePasswordModal from '../../Modal/ChangePasswordModal';
import codes from 'country-calling-code';
import { DateTime } from 'luxon';
import countryList from 'country-list';
import AccountCredentials from '../../Table/AccountCredentials';
import { selectDetectedLocation } from '../../../../detectedLocation/detectedLocationSlice';
import MigrationS3 from '../../Table/MigrationS3';
import ReportPDFs from '../../Table/ReportPDFs';

const ProfileSetting = () => {
  const profile = useAppSelector(selectUserProfile);
  const telegramInfo = useAppSelector(selectTelegramInfo);
  const { data, loading, page, perPage, total } = useAppSelector(selectDetectedLocation);
  const dispatch = useAppDispatch();
  const [key, setKey] = useState<string>('saved');
  const handleOpenChangePass = () => {
    dispatch(toggleShowModal(true));
  };

  return (
    <Container>
      <Row className="mt-3">
        <Col>
          <Col lg="12">
            <Breadcrumb>
              <Breadcrumb.Item linkAs="div">
                <NavLink to={`/detected-locations`} id="RouterNavLink">
                  Detected location
                </NavLink>
              </Breadcrumb.Item>

              <Breadcrumb.Item active>Profile</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-end">
          <Button
            className="me-1"
            onClick={() => {
              handleOpenChangePass();
            }}>
            Change password
          </Button>
        </Col>
      </Row>

      <Row className="mt-2 gx-5">
        <Col lg="7">
          <Container className="p-3 border bg-white rounded h-100">
            <Row>
              <Col lg="2" className="d-flex justify-content-center">
                <div
                  className="bg-secondary text-white p-3 fs-2  rounded-circle bg-opacity-75 d-flex  justify-content-center align-items-center"
                  style={{ width: '80px', height: '80px' }}>
                  {profile?.fullName.at(0)?.toLocaleUpperCase()}
                </div>
              </Col>
              <Col lg="10">
                <h3>{profile?.fullName}</h3>
                <p className="">Join at: {DateTime.fromISO(profile?.createdAt || '').toFormat('yyyy LLL dd')}</p>
              </Col>
              <Col>
                <div className="border-top border-dashed d-flex justify-content-between align-items-center pt-2 mt-2">
                  <div>
                    <h6 className="mb-2 text-start">Total saved</h6>
                    <h4 className=" mb-0">{total}</h4>
                  </div>

                  {/* <div className="text-start">
                    <h6 className="mb-2 ">Total exported</h6>
                    <h4 className=" mb-0">97 </h4>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col lg="5">
          <div className="p-3 border  bg-white rounded h-100">
            <div className="border-bottom border-dashed d-flex justify-content-between align-items-center pb-2">
              <h4 className=" lh-sm lh-xl-1">Information </h4>
              <Button
                variant="outline-primary"
                onClick={() => {
                  setKey('profile');
                }}>
                <BsFillPencilFill></BsFillPencilFill>
              </Button>
            </div>
            <div className="mt-3">
              <Row className=" flex-between-center mb-2">
                <Col className="">
                  <h6 className=" mb-0">Email</h6>
                </Col>
                <Col className="">
                  <a className="lh-1 text-decoration-none" href="mailto:shatinon@jeemail.com">
                    {profile?.email}
                  </a>
                </Col>
              </Row>
              <Row className=" flex-between-center mb-2">
                <Col className="">
                  <h6 className=" mb-0">Role</h6>
                </Col>
                <Col className="">
                  <a className="lh-1 text-decoration-none">{profile?.type == 0 ? 'Normal' : profile?.type == 1 ? 'Admin' : 'Super Admin'}</a>
                </Col>
              </Row>
              <Row className=" flex-between-center  mb-2">
                <Col className="">
                  <h6 className=" mb-0">Phone</h6>
                </Col>
                <Col className="">
                  <a className="text-decoration-none" href={`tel:${profile?.phone}`}>
                    {profile?.phone ? `(+${codes.find((it) => it.isoCode2 == profile?.phoneCode)?.countryCodes}) ${profile?.phone}` : ''}
                  </a>
                </Col>
              </Row>
              <Row className=" flex-between-center">
                <Col className="">
                  <h6 className=" mb-0">Location</h6>
                </Col>
                <Col className="">
                  <span className="text-decoration-none">{countryList.getName(profile?.location || 'SG')}</span>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <Tabs activeKey={key} onSelect={(k) => setKey(k || '')} id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="saved" title="Saved detected location">
              <SavedDetectedLocation></SavedDetectedLocation>
            </Tab>
            <Tab eventKey="profile" title="Profile setting">
              <UpdateProfileForm></UpdateProfileForm>
            </Tab>
            <Tab eventKey="telegram" title="Telegram integrate">
              <UpdateTelegramForm></UpdateTelegramForm>
            </Tab>
            {profile?.type === 2 && (
              <Tab eventKey="account" title="Account credentials">
                <AccountCredentials></AccountCredentials>
              </Tab>
            )}
            {profile?.type === 2 && (
              <Tab eventKey="migration" title="Migration AWS S3">
                <MigrationS3></MigrationS3>
              </Tab>
            )}

            <Tab eventKey="report-pdf" title="Report PDF">
              <ReportPDFs></ReportPDFs>
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <ChangePasswordModal />
    </Container>
  );
};

export default ProfileSetting;
