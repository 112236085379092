import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import authInstance from '../../Axios/authInstance';
import { DownloadPdfsParams, UploadDatasetResponse } from './uploadFolderType';
import { saveAs } from 'file-saver';

export const uploadDataset = createAsyncThunk<UploadDatasetResponse, FormData, { state: RootState }>(
  'uploadFolder/uploadDataset',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/s3/upload-dataset`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadBatchPavementFiles = createAsyncThunk<UploadDatasetResponse, FormData, { state: RootState }>(
  'uploadFolder/uploadBatchPavementFiles',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/s3/upload-batch-pavement-files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const DownloadAllPdfAsZip = createAsyncThunk<{ jobId: string }, DownloadPdfsParams, { state: RootState }>(
  'uploadFolderAll/pdf',
  async (DownloadPdfsParams, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(`/api/report-pdf/zip-all`, DownloadPdfsParams);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generateSummaryAndAllZip = createAsyncThunk<{ jobId: string }, DownloadPdfsParams, { state: RootState }>(
  'uploadFolderAll/pdf',
  async (DownloadPdfsParams, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(`/api/report-pdf/zip-all-generate`, DownloadPdfsParams);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generateSummaryAndZip = createAsyncThunk<{ jobId: string }, DownloadPdfsParams, { state: RootState }>(
  'uploadFolderAll/pdf',
  async (DownloadPdfsParams, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(`/api/report-pdf/zip-generate`, DownloadPdfsParams);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const DownloadPdfAsZip = createAsyncThunk<{ jobId: string }, { ids: string[]; userId: string; socketId: string }, { state: RootState }>(
  'uploadFolder/pdf',
  async ({ ids, userId, socketId }, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(`/api/report-pdf/zip`, { ids, userId, socketId });
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadDatasets = createAsyncThunk<{ file: string }, { ids: string[] }, { state: RootState }>(
  'uploadFolder/pdf',
  async ({ ids }, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(
        `/api/report-pdf/datasets`,
        { ids },
        {
          responseType: 'blob'
        }
      );

      const zipBlob = new Blob([response.data], { type: 'application/zip' });
      const fileName = `${new Date().getTime()}.zip`;
      saveAs(zipBlob, fileName);

      return { file: fileName };
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const migrationDatasets = createAsyncThunk<boolean, { ids: string[] }, { state: RootState }>(
  'migration/datasets',
  async ({ ids }, { rejectWithValue }) => {
    try {
      await authInstance.post(`/api/s3/migration-datasets`, { key: ids });
      return true;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const syncDataset = createAsyncThunk<boolean, { folderNames: string[] }, { state: RootState }>(
  'sync/datasets',
  async ({ folderNames }, { rejectWithValue }) => {
    try {
      await authInstance.post(`/api/s3/sync-datasets`, { folderNames });
      return true;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllReportPDFs = createAsyncThunk<any, { userId: string }, { state: RootState }>(
  'reportPDFs/getAllReportPDFs',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/report-pdf/all-download-zip`, { userId });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProgressPDFs = createAsyncThunk<any, { userId: string; socketId: string }, { state: RootState }>(
  'reportPDFs/updateProgressPDFs',
  async ({ userId, socketId }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/report-pdf/socket-progress`, { userId, socketId });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getReportPdfUrl = createAsyncThunk<any, { jobId: string }, { state: RootState }>(
  'reportPDFs/getAllReportPDFs',
  async ({ jobId }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/report-pdf/download-zip`, { jobId });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const cancelReportPDFs = createAsyncThunk<any, { jobId: string }, { state: RootState }>(
  'reportPDFs/cancelReportPDFs',
  async ({ jobId }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/report-pdf/cancel-pdf-job`, { jobId });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
